export interface Style {
  [variable: string]: string;
}

export const setFlowStyle = (style: Style) => {
  document.documentElement.setAttribute('data-theme', 'custom');

  for (let item in style) {
    document.documentElement.style.setProperty(item, style[item]);
  }
};
