import {formatDate, numberToDayOfTheWeek} from '../../services/dateFormat.ts';
import {convertTextToHtmlElements} from '../../services/textProcessor.tsx';
import {Flow} from '../../api/FlowAPI.ts';
import {CompanyProduct, CompanyProductCategory} from '../../api/ProductAPI.ts';
import {useEffect, useMemo, useState} from 'preact/compat';
import SelectedDate from './SelectedDate.tsx';
import SelectedCategory from './SelectedCategory.tsx';

interface Props {
  flow: Flow;
  selectedDate: Date | null;
  onDateChangeClick: () => void;
  companyProducts: CompanyProduct[];
  onProductSelected: (companyProduct: CompanyProduct) => void;
  onCategorySelected: (companyProductCategory: CompanyProductCategory | null) => void;
  selectedCategory: CompanyProductCategory | null;
}

type Selection = {type: 'product'; data: CompanyProduct} | {type: 'category'; data: CompanyProductCategory};

const ProductSelection = ({
  flow,
  selectedDate,
  onDateChangeClick,
  companyProducts,
  onProductSelected,
  onCategorySelected,
  selectedCategory,
}: Props) => {
  const [selections, setSelections] = useState<Selection[]>([]);

  const categoryProductMap = useMemo(() => {
    const map = new Map<string, CompanyProduct[]>();

    companyProducts.forEach((companyProduct) => {
      companyProduct.categories.forEach((companyProductCategory) => {
        if (!map.has(companyProductCategory.id)) {
          map.set(companyProductCategory.id, []);
        }

        map.get(companyProductCategory.id)!.push(companyProduct);
      });
    });

    return map;
  }, [companyProducts]);

  useEffect(() => {
    if (selectedCategory !== null && flow.is_timetable) {
      return;
    }

    const updatedSelections: Selection[] = [];

    if (selectedCategory === null) {
      const addedCategories = new Set();

      companyProducts.forEach((companyProduct) => {
        if (companyProduct.categories.length === 0) {
          updatedSelections.push({
            type: 'product',
            data: companyProduct,
          });
        } else {
          companyProduct.categories.forEach((companyProductCategory) => {
            if (!addedCategories.has(companyProductCategory.id)) {
              updatedSelections.push({
                type: 'category',
                data: companyProductCategory,
              });

              addedCategories.add(companyProductCategory.id);
            }
          });
        }
      });
    } else {
      const categoryProducts = categoryProductMap.get(selectedCategory.id);

      if (categoryProducts) {
        categoryProducts.forEach((companyProduct) => {
          updatedSelections.push({
            type: 'product',
            data: companyProduct,
          });
        });
      }
    }

    setSelections(updatedSelections);
  }, [companyProducts, categoryProductMap, selectedCategory]);

  const isProductDisabled = (companyProduct: CompanyProduct) => {
    if (selectedDate === null || flow.products_before_calendar) {
      return false;
    }

    if (new Date(companyProduct.maximum_date) < selectedDate) {
      return true;
    }

    if (new Date(`${companyProduct.minimum_date}T00:00:00`) > selectedDate) {
      return true;
    }

    const dayOfTheWeek = numberToDayOfTheWeek(selectedDate.getDay() as any);

    if (companyProduct.availability[dayOfTheWeek] === null) {
      if (companyProduct.calendar_type === 'auto' && companyProduct.custom_dates.length !== 0) {
        const formattedDate = formatDate(selectedDate);

        if (companyProduct.custom_dates.indexOf(formattedDate) === -1) {
          return true;
        }
      } else {
        return true;
      }
    }

    const formattedDate = formatDate(selectedDate);

    if (companyProduct.calendar_type === 'manual') {
      if (companyProduct.custom_dates.indexOf(formattedDate) === -1) {
        return true;
      }
    }

    return companyProduct.disabled_dates.indexOf(formattedDate) !== -1;
  };

  const isCategoryDisabled = (companyProductCategory: CompanyProductCategory) => {
    if (selectedDate === null || flow.products_before_calendar) {
      return false;
    }

    const categoryProducts = categoryProductMap.get(companyProductCategory.id);

    if (categoryProducts) {
      return categoryProducts.every((companyProduct) => isProductDisabled(companyProduct));
    }

    return false;
  };

  const renderSelectedDate = () => {
    if (selectedDate === null || flow.products_before_calendar) {
      return null;
    }

    return <SelectedDate selectedDate={selectedDate} onDateChangeClick={onDateChangeClick} />;
  };

  const renderSelectedCategory = () => {
    if (selectedCategory === null) {
      return null;
    }

    return (
      <SelectedCategory selectedCategory={selectedCategory} onCategoryChangeClick={() => onCategorySelected(null)} />
    );
  };

  const onCategoryClick = (companyProductCategory: CompanyProductCategory) => {
    if (flow.is_timetable) {
      companyProductCategory.category_product_ids = categoryProductMap
        .get(companyProductCategory.id)!
        .map((product) => product.id);
    }

    onCategorySelected(companyProductCategory);
  };

  const renderSelection = (selection: Selection) => {
    if (selection.type === 'product') {
      const companyProduct = selection.data;

      return (
        <button
          key={companyProduct.id}
          disabled={isProductDisabled(companyProduct)}
          onClick={() => onProductSelected(companyProduct)}
          className='w-full btn btn-primary btn-outline btn-primary-outline-fix'
        >
          {companyProduct.title}
        </button>
      );
    }

    if (selection.type === 'category') {
      const companyProductCategory = selection.data;

      return (
        <button
          key={companyProductCategory.id}
          disabled={isCategoryDisabled(companyProductCategory)}
          onClick={() => onCategoryClick(companyProductCategory)}
          className='w-full btn btn-primary btn-outline btn-primary-outline-fix'
        >
          {companyProductCategory.title}
        </button>
      );
    }

    return null;
  };

  return (
    <>
      {renderSelectedDate()}
      {!flow.products_before_calendar && renderSelectedCategory()}
      <div className='flex flex-col gap-2 overflow-y-auto px-2 max-w-2xl mx-auto w-full py-1'>
        {flow.texts?.product_select && selectedCategory === null && (
          <p className='mb-6 whitespace-pre-wrap product-select'>{convertTextToHtmlElements(flow.texts.product_select)}</p>
        )}
        {selectedCategory !== null && selectedCategory.description !== null && (
          <p className='mb-6 whitespace-pre-wrap'>{convertTextToHtmlElements(selectedCategory.description)}</p>
        )}
        {flow.products_before_calendar && renderSelectedCategory()}
        {selections.map((selection, index) => (
          <div className='w-full' key={selection.type + index}>
            {renderSelection(selection)}
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductSelection;
