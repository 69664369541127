import {useEffect, useRef, useState} from 'preact/compat';
import {TbChevronCompactLeft, TbChevronCompactRight} from 'react-icons/tb';
import {Flow, ProductWithAvailability} from '../../api/FlowAPI.ts';
import {ProductAvailableTime} from '../../api/ProductAPI.ts';
import {useTranslation} from 'react-i18next';

interface Props {
  productsWithAvailability: ProductWithAvailability[];
  layout: 'vertical' | 'horizontal';
  onTimeSelected: (productId: string, time: ProductAvailableTime) => void;
  flow: Flow;
  embedded?: boolean;
}

const ProductsTimeTable = ({productsWithAvailability, layout, onTimeSelected, flow, embedded}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [isScrollable, setIsScrollable] = useState(false);
  const [isAtScrollStart, setIsAtScrollStart] = useState(true);
  const [isAtScrollEnd, setIsAtScrollEnd] = useState(false);

  const {t} = useTranslation();

  useEffect(() => {
    if (ref && ref.current) {
      const isContentScrollable = ref.current.scrollWidth > ref.current.clientWidth;

      setIsScrollable(isContentScrollable);

      if (!isContentScrollable) {
        return;
      }

      const checkScroll = () => {
        setIsAtScrollEnd(ref.current!.scrollLeft + ref.current!.clientWidth >= ref.current!.scrollWidth);
        setIsAtScrollStart(ref.current!.scrollLeft <= 0);
      };

      ref.current.addEventListener('scroll', checkScroll);

      return () => {
        ref.current!.removeEventListener('scroll', checkScroll);
      };
    }
  }, [ref.current, layout]);

  if (productsWithAvailability.every((companyProduct) => companyProduct.availability.length === 0)) {
    return <div className='text-lg text-error text-center'>{t('no_available_times')}</div>;
  }

  const scrollHorizontally = (scrollOffset: number) => {
    if (ref.current) {
      ref.current.scrollLeft += scrollOffset;
    }
  };

  const renderScrollControls = () => {
    if (!isScrollable) {
      return null;
    }

    return (
      <>
        <button
          disabled={isAtScrollStart}
          className='absolute top-1/3 left-0 -translate-x-[22px]'
          onClick={() => scrollHorizontally(-200)}
        >
          <TbChevronCompactLeft className={isAtScrollStart ? 'text-gray-200' : ''} size={60} />
        </button>
        <button
          disabled={isAtScrollEnd}
          className='absolute top-1/3 right-0 translate-x-[22px]'
          onClick={() => scrollHorizontally(200)}
        >
          <TbChevronCompactRight className={isAtScrollEnd ? 'text-gray-200' : ''} size={60} />
        </button>
      </>
    );
  };

  const availableHoursSet = new Set<string>();
  let anyProductHasAvailableSlots = false;

  productsWithAvailability.forEach((productWithAvailability) => {
    productWithAvailability.availability.forEach((slot) => {
      availableHoursSet.add(slot.from.substring(0, 2));

      if (!anyProductHasAvailableSlots && slot.available_slots !== null) {
        anyProductHasAvailableSlots = true;
      }
    });
  });

  const availableHours = Array.from(availableHoursSet).sort();

  if (layout === 'vertical') {
    return (
      <div
        className={`${
          isScrollable ? 'px-4' : ''
        } overflow-y-auto overflow-x-hidden relative flex flex-col max-w-5xl mx-auto w-full lg:max-h-none max-h-[400px] ${
          embedded && !flow.embedding_settings?.modal ? 'lg:max-h-[500px] max-h-[500px]' : ''
        }`}
      >
        {renderScrollControls()}
        <div className='overflow-x-auto scroll-smooth mx-auto z-20 relative w-full' ref={ref}>
          <table className='bg-base-100 table border-separate table-pin-rows border-b border-spacing-0 rounded-none'>
            <thead>
              <tr>
                <th className='w-0 sticky bg-base-100 left-0 border-x border-t'></th>
                {productsWithAvailability.map((productWithAvailability, index) => (
                  <th
                    key={index}
                    className={`whitespace-normal text-center items-center border-r border-t text-base-content ${
                      productWithAvailability.availability.length === 0 ? 'bg-gray-100' : ''
                    }`}
                  >
                    {productWithAvailability.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {availableHours.map((hour, index) => (
                <tr key={index}>
                  <th className='px-0 text-center sticky left-0 bg-base-100 border-x border-t text-xs font-semibold text-gray-500'>
                    {hour}
                  </th>
                  {productsWithAvailability.map((productWithAvailability, productIndex) => (
                    <td
                      key={productIndex}
                      className={`p-0 border-r border-t align-top ${
                        productWithAvailability.availability.length === 0 ? 'bg-gray-100' : ''
                      }`}
                    >
                      <div className='flex flex-col gap-1 p-1'>
                        {productWithAvailability.availability
                          .filter(({from}) => from.startsWith(hour))
                          .map((timeData, index) => (
                            <button
                              key={index}
                              className={`btn flex-col btn-primary btn-outline btn-sm w-full py-1 whitespace-nowrap min-h-[46px] ${
                                anyProductHasAvailableSlots ? 'h-[4rem]' : 'h-auto'
                              }`}
                              disabled={!timeData.available}
                              onClick={() => onTimeSelected(productWithAvailability.id, timeData)}
                            >
                              <div className='flex flex-col gap-1'>
                                {timeData.from}{!flow.hide_end_times && ` - ${timeData.to}`}
                                {!timeData.attendees_mismatch && timeData.price && <div>{timeData.price}€</div>}
                                {timeData.attendees_mismatch ? (
                                  <div className='text-xs normal-case text-error whitespace-nowrap'>
                                    {flow.texts?.attendees_count || t('attendees_count')}{' '}
                                    {productWithAvailability.minimum_attendees} -{' '}
                                    {productWithAvailability.maximum_attendees}
                                  </div>
                                ) : (
                                  timeData.available_slots && (
                                    <div
                                      className={`lowercase first-letter:uppercase  ${
                                        timeData.attendees_mismatch ? 'text-error' : 'text-secondary'
                                      }`}
                                    >
                                      {t('slots_left', {count: timeData.available_slots})}
                                    </div>
                                  )
                                )}
                              </div>
                            </button>
                          ))}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`${isScrollable ? 'px-4' : ''} overflow-y-auto overflow-x-hidden relative ${
          embedded && !flow.embedding_settings?.modal ? 'max-h-[500px]' : ''
        }`}
      >
        {renderScrollControls()}
        <div className='overflow-x-auto scroll-smooth mx-auto z-20 relative h-full' ref={ref}>
          <table className='bg-base-100 table table-pin-rows border-separate border-b border-spacing-0 rounded-none'>
            <thead>
              <tr>
                <th className='sticky left-0 bg-base-100 border-x border-t'></th>
                {availableHours.map((hour) => (
                  <th key={hour} className='text-center border-t border-r'>
                    {hour}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {productsWithAvailability.map((productWithAvailability, productIndex) => (
                <tr key={productIndex}>
                  <th
                    className={`sticky min-w-[120px] left-0 bg-base-100 border-x border-t ${
                      productWithAvailability.availability.length === 0 ? 'bg-gray-100' : ''
                    }`}
                  >
                    {productWithAvailability.title}
                  </th>
                  {availableHours.map((hour) => (
                    <td
                      key={hour}
                      className={`p-0 border-t border-r align-middle ${
                        productWithAvailability.availability.length === 0 ? 'bg-gray-100' : ''
                      }`}
                    >
                      <div className='flex gap-1 p-1 min-h-[54px]'>
                        {productWithAvailability.availability
                          .filter(({from}) => from.startsWith(hour.substring(0, 2)))
                          .map((timeData, index) => (
                            <button
                              key={index}
                              className={`btn shrink btn-sm flex-col py-1 min-h-[46px] min-w-[84px] ${
                                timeData.available ? 'btn-primary btn-outline' : ''
                              } ${anyProductHasAvailableSlots ? 'h-[4rem]' : 'h-auto'}`}
                              disabled={!timeData.available}
                              onClick={() => onTimeSelected(productWithAvailability.id, timeData)}
                            >
                              <div className='flex flex-col gap-1'>
                                <div className='whitespace-nowrap'>
                                  {timeData.from}{!flow.hide_end_times && ` - ${timeData.to}`}
                                </div>
                                {!timeData.attendees_mismatch && timeData.price && <div>{timeData.price}€</div>}
                                {timeData.attendees_mismatch ? (
                                  <div className='text-xs normal-case text-error whitespace-nowrap'>
                                    {flow.texts?.attendees_count || t('attendees_count')}{' '}
                                    {productWithAvailability.minimum_attendees} -{' '}
                                    {productWithAvailability.maximum_attendees}
                                  </div>
                                ) : (
                                  timeData.available_slots && (
                                    <div
                                      className={`lowercase first-letter:uppercase  ${
                                        timeData.attendees_mismatch ? 'text-error' : 'text-secondary'
                                      }`}
                                    >
                                      {t('slots_left', {count: timeData.available_slots})}
                                    </div>
                                  )
                                )}
                              </div>
                            </button>
                          ))}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};

export default ProductsTimeTable;
