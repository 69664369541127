export const convertTextToHtmlElements = (inputText: string) => {
  const regex = /<b>(.*?)<\/b>/g;
  const parts = inputText.split(regex);

  return parts.map((part, index) => {
    if (index % 2 === 1) {
      return <strong key={index}>{part}</strong>;
    }

    return part;
  });
};
