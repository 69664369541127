import {RiEditFill} from 'react-icons/ri';

interface Props {
  title: string;
  onAttendeesChangeClick: () => void;
  selectedAttendees: number;
}

const SelectedAttendees = ({title, onAttendeesChangeClick, selectedAttendees}: Props) => {
  return (
    <div
      key='selected-attendees'
      className='selected-booking-item'
      onClick={onAttendeesChangeClick}
    >
      {title}: {selectedAttendees}
      <RiEditFill className='absolute top-1/2 -translate-y-1/2 right-4' />
    </div>
  );
};

export default SelectedAttendees;
