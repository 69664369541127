import {render} from 'preact';
import {App} from './app.tsx';
import './index.css';
import './overlay.scss';

import './i18n';

const loadSentry = () => import('@sentry/react');

if (import.meta.env.VITE_SENTRY_DSN) {
  loadSentry().then(({init, BrowserTracing, Replay}) => {
    init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          tracePropagationTargets: [import.meta.env.VITE_DOMAIN],
        }),
        new Replay(),
      ],
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  });
}

render(<App />, document.getElementById('app') as HTMLElement);
