import {addMinutes, format} from 'date-fns';

export type DayOfWeek = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

const numberToDayOfTheWeekMap: Record<number, DayOfWeek> = {
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
  0: 'sun',
};

export const numberToDayOfTheWeek = (number: 1 | 2 | 3 | 4 | 5 | 6 | 0): DayOfWeek => numberToDayOfTheWeekMap[number];
export const formatDate = (date: Date) => format(date, 'y-MM-dd');

export const getTimeIntervals = (startTime: string, endTime: string, interval: number) => {
  const intervals = [];
  let startDate = new Date(`2000-01-01T${startTime}`);
  const endDate = startTime > endTime || startTime === endTime ? new Date(`2000-01-02T${endTime}`) : new Date(`2000-01-01T${endTime}`);

  if (startTime === endTime) {
    while (startDate < endDate) {
      intervals.push(format(startDate, 'HH:mm'));
      startDate = addMinutes(startDate, interval);
    }
  } else {
    while (startDate <= endDate) {
      intervals.push(format(startDate, 'HH:mm'));
      startDate = addMinutes(startDate, interval);
    }
  }

  return intervals;
};
