import {RiEditFill} from 'react-icons/ri';
import {CompanyProductCategory} from '../../api/ProductAPI.ts';

interface Props {
  selectedCategory: CompanyProductCategory;
  onCategoryChangeClick: () => void;
}

const SelectedCategory = ({selectedCategory, onCategoryChangeClick}: Props) => {
  return (
    <div
      key='selected-category'
      className='selected-booking-item'
      onClick={onCategoryChangeClick}
    >
      {selectedCategory.title}
      <RiEditFill className='absolute top-1/2 -translate-y-1/2 right-4' />
    </div>
  );
};

export default SelectedCategory;
