import {Flow} from '../../api/FlowAPI.ts';
import {CompanyProduct, ProductAvailableTime, ProductBookingData} from '../../api/ProductAPI.ts';
import {useTranslation} from 'react-i18next';
import {useMemo, useState} from 'preact/compat';
import {buildBookingDetails} from '../../services/booking.ts';
import {FaTh} from 'react-icons/fa';

interface Props {
  flow: Flow;
  selectedTime: ProductAvailableTime | null;
  selectedCompanyProduct: CompanyProduct;
  waitingForRedirect: boolean;
  onSummarySubmit: (advancePayment?: boolean, payLater?: boolean) => void;
  productBookingData: ProductBookingData;
  finalPriceWithDiscount: JSX.Element | null;
}

const Summary = ({
  flow,
  selectedTime,
  selectedCompanyProduct,
  waitingForRedirect,
  onSummarySubmit,
  productBookingData,
  finalPriceWithDiscount,
}: Props) => {
  const [termsError, setTermsError] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [payLater, setPayLater] = useState<boolean>(false);

  const {t} = useTranslation();

  const onTermsToggle = () => {
    setTermsAccepted(!termsAccepted);
    setTermsError(false);
  };

  const onSubmit = async (advancePayment?: boolean, payLater?: boolean) => {
    if (!termsAccepted) {
      setTermsError(true);

      return;
    }

    await onSummarySubmit(advancePayment, payLater);
  };

  const payLaterElement = useMemo(() => {
    return (
      <div className='mb-4 flex gap-2 items-center justify-center'>
        <input className='checkbox' type='checkbox' onChange={() => setPayLater(!payLater)} checked={payLater} />
        <div className='text-sm'>{flow.texts?.pay_later || t('pay_later')}</div>
      </div>
    );
  }, [payLater]);

  const isWithCheckout = () => {
    return (
      flow.with_checkout &&
      selectedTime !== null &&
      selectedTime.final_price !== null &&
      selectedTime.final_price !== '0.00' &&
      !selectedCompanyProduct!.disable_checkout
    );
  };

  const renderSummarySubmitOptions = () => {
    if (isWithCheckout() && selectedTime && !payLater) {
      if (selectedTime.advance_price !== null) {
        if (selectedCompanyProduct!.only_advance_payment) {
          return (
            <div className='max-w-2xl mx-auto'>
              {flow.pay_later_enabled && payLaterElement}
              <button
                disabled={waitingForRedirect}
                className='btn btn-primary w-full mt-3'
                onClick={() => onSubmit(true)}
              >
                {t('checkout_advance_price', {amount: selectedTime.advance_price})}
              </button>
            </div>
          );
        }

        if (Number(selectedTime.final_price) > Number(selectedTime.advance_price)) {
          return (
            <div className='max-w-2xl mx-auto'>
              <p className='text-center mb-3 font-medium text-lg'>{t('select_payment_type')}</p>
              {flow.pay_later_enabled && payLaterElement}
              <button disabled={waitingForRedirect} className='btn btn-primary w-full' onClick={() => onSubmit()}>
                {t('checkout_full_price', {amount: selectedTime.final_price})}
              </button>
              <div className='text-center my-2 text-sm'>{t('or')}</div>
              <button disabled={waitingForRedirect} className='btn btn-accent w-full' onClick={() => onSubmit(true)}>
                {t('checkout_advance_price', {amount: selectedTime.advance_price})}
              </button>
            </div>
          );
        }
      }

      return (
        <div className='max-w-2xl mx-auto'>
          {flow.pay_later_enabled && payLaterElement}
          <button disabled={waitingForRedirect} className='btn btn-primary w-full mt-3' onClick={() => onSubmit()}>
            {t('checkout', {amount: selectedTime.final_price})}
          </button>
        </div>
      );
    }

    return (
      <div className='max-w-2xl mx-auto'>
        {isWithCheckout() && flow.pay_later_enabled && payLaterElement}
        <button className='btn btn-primary w-full mt-3' onClick={() => onSubmit(undefined, payLater)}>
          {t('submit')}
        </button>
      </div>
    );
  };

  return (
    <>
      <h2 className='font-semibold text-2xl text-center mb-4'>
        {productBookingData!.attendees_count && (
          <div className='flex text-base justify-center items-center'>
            <FaTh
              title={
                (flow.is_timetable && flow.texts?.attendees_count) ||
                selectedCompanyProduct!.flow_texts.attendees_count ||
                t('attendees_count')!
              }
              size={18}
              className='mr-2'
            />
            {productBookingData!.attendees_count}
          </div>
        )}
        <div className='uppercase text-xl'>{selectedCompanyProduct!.title}</div>
        {selectedTime !== null && (
          <div className='text-base'>
            {productBookingData!.date} {productBookingData!.time}{' '}
            {selectedTime.final_price !== null && finalPriceWithDiscount}
            {!isWithCheckout() && selectedTime.final_price !== '0.00' && selectedTime.advance_price && (
              <div>
                {t('advance_payment')}: {selectedTime.advance_price}€
              </div>
            )}
          </div>
        )}
      </h2>
      {selectedCompanyProduct!.flow_texts?.summary_additional && (
        <p className='text-center mb-4'>{selectedCompanyProduct!.flow_texts?.summary_additional}</p>
      )}
      <div>
        <div className='my-6 text-sm max-w-sm mx-auto'>
          {buildBookingDetails(selectedCompanyProduct!, productBookingData!).map(({label, value}) => (
            <div className='mb-2 border-b flex gap-4 justify-between items-center flex-nowrap' key={label}>
              {label}: <span className='font-medium'>{value}</span>
            </div>
          ))}
        </div>
      </div>
      <div className='mb-4 flex gap-2 items-center max-w-2xl mx-auto'>
        <input
          className={`checkbox${termsError ? ' checkbox-error border-2' : ''}`}
          type='checkbox'
          onChange={onTermsToggle}
          checked={termsAccepted}
        />
        <p
          className='text-sm'
          dangerouslySetInnerHTML={{
            __html: t('agree_with_terms', {
              companyName: flow.company_name,
              companyTermsUrl: selectedCompanyProduct!.terms_url,
            }),
          }}
        />
      </div>
      <div>{renderSummarySubmitOptions()}</div>
    </>
  );
};

export default Summary;
