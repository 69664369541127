import {BookingData} from '../api/BookingAPI.ts';
import {useTranslation} from 'react-i18next';
import {FiExternalLink} from 'react-icons/fi';
import {FaTh} from 'react-icons/fa';
import {CompanyProduct} from '../api/ProductAPI.ts';
import {useEffect, useState} from 'preact/compat';
import {buildBookingDetails} from '../services/booking.ts';

interface Props {
  bookingData: BookingData;
  companyProduct?: CompanyProduct;
  bookingDetails?: {
    [key: string]: string;
  }[];
}

const BookingStateWithDetails = ({companyProduct, bookingData}: Props) => {
  const [bookingDetails, setBookingDetails] = useState<
    | {
        [key: string]: string;
      }[]
    | undefined
  >();

  const {t} = useTranslation();

  useEffect(() => {
    if (companyProduct) {
      setBookingDetails(buildBookingDetails(companyProduct, bookingData));
    }
  }, [companyProduct, bookingData]);

  const buildStatus = () => {
    if (bookingData.status === 'confirmed') {
      return t('status.confirmed');
    }

    if (bookingData.status === 'completed') {
      return t('status.completed');
    }

    if (bookingData.status === 'canceled') {
      return t('status.canceled');
    }

    if (bookingData.payment_status !== null) {
      if (bookingData.payment_status === 'started' || bookingData.payment_status === 'pay_later') {
        return t('status.not_paid');
      }

      if (bookingData.payment_status === 'processing') {
        return t('status.waiting_for_payment');
      }
    }

    return t('status.waiting_for_confirmation');
  };

  return (
    <div>
      <div className='font-semibold text-2xl text-center'>
        {bookingData.attendees_count !== null && (
          <div className='flex text-base justify-center items-center'>
            <FaTh
              title={
                bookingData.flow_texts?.attendees_count ||
                companyProduct?.flow_texts.attendees_count ||
                t('attendees_count')!
              }
              className='mr-2'
            />
            {bookingData.attendees_count}
          </div>
        )}
      </div>
      <h2 className='uppercase text-xl text-center font-semibold'>{bookingData.product_title}</h2>
      {bookingData.product_address_data !== null && (
        <div>
          <div className='text-center mb-2'>
            {bookingData.product_address_data.location ? (
              <a
                className='flex justify-center items-center gap-1'
                target='_blank'
                href={`https://www.google.com/maps?q=${bookingData.product_address_data.location.latitude},${bookingData.product_address_data.location.longitude}`}
              >
                {bookingData.product_address_data.address} <FiExternalLink size={14} />
              </a>
            ) : (
              bookingData.product_address_data.address
            )}
          </div>
        </div>
      )}
      <div className='text-center font-semibold mb-2'>
        {bookingData.date} {bookingData.time}
      </div>
      <div className='text-center mt-4 mb-2'>
        {t('booking_status')}: <span className='font-semibold'>{buildStatus()}</span>
      </div>
      {bookingData.price !== null && (
        <div className='text-center'>
          {t('total_price')}: <span className='font-semibold'>{bookingData.price}€</span>
        </div>
      )}
      {bookingData.advance_price && (
        <div className='text-center mb-2'>
          {t('advance_payment')}: <span className='font-semibold'>{bookingData.advance_price}€</span>
        </div>
      )}
      <div className='my-6 text-sm max-w-sm mx-auto'>
        {bookingDetails &&
          bookingDetails.map(({label, value}) => (
            <div className='mb-2 border-b flex gap-4 justify-between items-center flex-nowrap' key={label}>
              {label} <span className='font-medium'>{t(value)}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BookingStateWithDetails;
