import {useEffect, useMemo, useState} from 'preact/compat';
import Calendar from 'react-calendar';
import {
  CompanyProduct,
  CompanyProductCategory,
  ContactFormField,
  createProductBooking,
  fetchAvailableTimes,
  ProductAvailableTime,
  ProductBookingData,
} from '../api/ProductAPI.ts';
import '../calendar.css';
import {DayOfWeek, formatDate, numberToDayOfTheWeek} from '../services/dateFormat.ts';
import {IoCalendarNumberSharp, IoCheckmarkSharp, IoChevronBackSharp} from 'react-icons/io5';
import {AiOutlineForm} from 'react-icons/ai';
import {parseError} from '../services/errorHandler.ts';
import {useTranslation} from 'react-i18next';
import {
  confirmFlowPayment,
  fetchFlowProductsWithAvailability,
  Flow,
  FlowPaymentData,
  ProductWithAvailability,
} from '../api/FlowAPI.ts';
import {BookingData, fetchBooking} from '../api/BookingAPI.ts';
import BookingStateWithDetails from './BookingStateWithDetails.tsx';
import ProductSelection from './Booking/ProductSelection.tsx';
import {checkCommonEmailMistypes} from '../services/emailChecker.ts';
import Loading from './Loading.tsx';
import AttendeesSelection from './Booking/AttendeesSelection.tsx';
import ProductsTimeTable from './Booking/ProductsTimeTable.tsx';
import SelectedDate from './Booking/SelectedDate.tsx';
import SelectedCategory from './Booking/SelectedCategory.tsx';
import SelectedProduct from './Booking/SelectedProduct.tsx';
import SelectedAttendees from './Booking/SelectedAttendees.tsx';
import SelectedTime from './Booking/SelectedTime.tsx';
import DiscountCodeInput from './Booking/DiscountCodeInput.tsx';
import Summary from './Booking/Summary.tsx';
import PhoneField from './Booking/ContactForm/PhoneField.tsx';

interface Props {
  companyProducts: CompanyProduct[];
  flow: Flow;
  onCheckoutStart: (productBooking: BookingData) => void;
  action: 'success' | 'cancel' | 'status' | null | string;
  bookingId: string | null;
  checkoutData: FlowPaymentData | null;
  embedded?: boolean;
  onStepChange?: (step: Step) => void;
  onSubStepDone?: (subStep: SubStep) => void;
  onEvent?: (name: string, data: EventData) => void;
}

export type Step = 'attendees' | 'product' | 'date' | 'contact' | 'summary' | 'success';
export type SubStep = 'category' | 'product' | 'date' | 'time' | 'timetable' | 'attendees' | 'contact' | 'summary';

export interface EventData {
  product?: {
    id: string;
    title: string;
  };
  booking?: {
    price: string | null;
    advance_price: string | null;
  };
  step?: Step;
}

const stepsMap: {[key: number]: Step} = {
  1: 'product',
  2: 'date',
  3: 'contact',
  4: 'attendees',
  5: 'summary',
  6: 'success',
};

const Booking = ({
  companyProducts,
  onCheckoutStart,
  action,
  flow,
  checkoutData,
  bookingId,
  embedded = false,
  onStepChange,
  onEvent,
  onSubStepDone,
}: Props) => {
  const [selectedCompanyProduct, setSelectedCompanyProduct] = useState<CompanyProduct | null>(
    companyProducts.length === 1 ? companyProducts[0] : null
  );

  let initialStep = 2;

  if (companyProducts.length !== 1) {
    if (flow.products_before_calendar) {
      if (flow.is_timetable && companyProducts.some((product) => product.categories.length === 0)) {
        initialStep = 2;
      } else {
        initialStep = 1;
      }
    }
  }

  const [step, setStep] = useState<Step>(stepsMap[initialStep]);
  const [stepId, setStepId] = useState<number>(initialStep);

  const [selectedAttendees, setSelectedAttendees] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [activeStartDate, setActiveStartDate] = useState<Date | undefined>(undefined);
  const [selectedTime, setSelectedTime] = useState<ProductAvailableTime | null>(null);
  const [isLoadingAvailability, setIsLoadingAvailability] = useState<boolean>(true);
  const [availableTimes, setAvailableTimes] = useState<ProductAvailableTime[] | null>(null);
  const [productBookingData, setProductBookingData] = useState<ProductBookingData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [onBack, setOnBack] = useState<(() => void) | null>(null);
  const [attendeesCount, setAttendeesCount] = useState<number | null>(
    companyProducts.length === 1 ? companyProducts[0].minimum_attendees : null
  );
  const [isLoadingProductBooking, setIsLoadingProductBooking] = useState(true);
  const [productBooking, setProductBooking] = useState<BookingData | null>(null);
  const [waitingForRedirect, setWaitingForRedirect] = useState<boolean>(false);
  const [contactErrors, setContactErrors] = useState<{[name: string]: string}>({});
  const [selectedCategory, setSelectedCategory] = useState<CompanyProductCategory | null>(null);
  const [productsWithAvailability, setProductsWithAvailability] = useState<ProductWithAvailability[] | null>(null);
  const [discountCode, setDiscountCode] = useState<string | undefined>(undefined);
  const [discountCodeApplied, setDiscountCodeApplied] = useState<boolean>(false);

  const {
    t,
    i18n: {language},
  } = useTranslation();

  const sendEvent = (name: string, data: EventData) => {
    if (onEvent) {
      onEvent(name, data);
    }
  };

  useEffect(() => {
    if (action !== null) {
      (async () => {
        if (action === 'success' && checkoutData !== null) {
          await confirmFlowPayment(flow.id, checkoutData);
        }

        if (bookingId !== null) {
          const {data: bookingData} = await fetchBooking(bookingId);

          setProductBooking(bookingData);
          setIsLoadingProductBooking(false);

          sendEvent(`booking_${action}`, {
            product: {
              id: bookingData.product_id,
              title: bookingData.product_title,
            },
            booking: {
              price: bookingData.price,
              advance_price: bookingData.advance_price,
            },
          });
        }
      })();

      return;
    }

    if (onStepChange) {
      onStepChange(step);
    }

    sendEvent('booking_step_changed', {
      step,
      product: selectedCompanyProduct
        ? {
            id: selectedCompanyProduct.id,
            title: selectedCompanyProduct.title,
          }
        : undefined,
      booking: productBooking
        ? {
            price: productBooking.price,
            advance_price: productBooking.advance_price,
          }
        : undefined,
    });

    setOnBack(() => () => {
      previousStep(step);
    });

    setError(null);
  }, [step]);

  if (action === 'success') {
    let currentBookingProduct;

    if (productBooking !== null) {
      currentBookingProduct = companyProducts.find((companyProduct) => companyProduct.id === productBooking.product_id);
    }

    return (
      <div>
        {isLoadingProductBooking && <Loading />}
        {!isLoadingProductBooking && productBooking !== null && (
          <>
            <p className='text-success mb-4 text-center'>{productBooking.flow_texts.checkout_success}</p>
            <BookingStateWithDetails bookingData={productBooking} companyProduct={currentBookingProduct} />
          </>
        )}
      </div>
    );
  }

  const onRetryPaymentClick = async () => {
    setWaitingForRedirect(true);

    const {data: bookingData} = await fetchBooking(productBooking!.id);

    if (
      bookingData.payment_status === 'started' ||
      bookingData.payment_status === 'processing' ||
      bookingData.payment_status === 'pay_later'
    ) {
      onCheckoutStart(bookingData);
    } else {
      //todo: error
    }
  };

  if (action === 'status') {
    let currentBookingProduct;

    if (productBooking !== null) {
      currentBookingProduct = companyProducts.find((companyProduct) => companyProduct.id === productBooking.product_id);
    }

    return (
      <div>
        {isLoadingProductBooking && <Loading />}
        {!isLoadingProductBooking && productBooking !== null && (
          <>
            <BookingStateWithDetails bookingData={productBooking} companyProduct={currentBookingProduct} />
            {productBooking.status !== 'canceled' &&
              (productBooking.payment_status === 'started' ||
                productBooking.payment_status === 'processing' ||
                productBooking.payment_status === 'pay_later') &&
              productBooking.checkout_data !== null && (
                <div className='text-center'>
                  <button disabled={waitingForRedirect} className='btn btn-primary' onClick={onRetryPaymentClick}>
                    {t('retry_payment', {amount: productBooking.advance_price ?? productBooking.price})}
                  </button>
                </div>
              )}
          </>
        )}
      </div>
    );
  }

  if (action === 'cancel') {
    let currentBookingProduct;

    if (productBooking !== null) {
      currentBookingProduct = companyProducts.find((companyProduct) => companyProduct.id === productBooking.product_id);
    }

    return (
      <div>
        {isLoadingProductBooking && <Loading />}
        {!isLoadingProductBooking && productBooking !== null && (
          <>
            <p className='text-error text-center mb-4'>{productBooking.flow_texts.checkout_cancel}</p>
            <BookingStateWithDetails bookingData={productBooking} companyProduct={currentBookingProduct} />
            <div className='text-center'>
              <button disabled={waitingForRedirect} className='btn btn-primary' onClick={onRetryPaymentClick}>
                {t('retry_payment', {amount: productBooking.advance_price ?? productBooking.price})}
              </button>
            </div>
          </>
        )}
      </div>
    );
  }

  const nextStep = () => {
    if (
      step === 'contact' &&
      (selectedCompanyProduct!.attendees_before_time ||
        !selectedCompanyProduct!.with_attendees ||
        (flow.is_timetable && selectedAttendees !== null))
    ) {
      setStepId(stepId + 2);
      setStep(stepsMap[stepId + 2]);
    } else {
      setStepId(stepId + 1);
      setStep(stepsMap[stepId + 1]);
    }
  };

  const previousStep = (currentStep: Step) => {
    if (currentStep === 'summary' && flow.is_timetable) {
      if (
        selectedCompanyProduct!.with_attendees &&
        companyProducts.every((product) => !product.attendees_before_time)
      ) {
        setStepId(stepId - 1);
        setStep(stepsMap[stepId - 1]);
        setSelectedAttendees(null);
      } else {
        setStepId(stepId - 2);
        setStep(stepsMap[stepId - 2]);
      }
    } else if (
      currentStep === 'summary' &&
      (selectedCompanyProduct!.attendees_before_time || !selectedCompanyProduct!.with_attendees)
    ) {
      setStepId(stepId - 2);
      setStep(stepsMap[stepId - 2]);
    } else {
      setStepId(stepId - 1);
      setStep(stepsMap[stepId - 1]);
    }

    if (currentStep === 'date') {
      setSelectedAttendees(null);
      setSelectedDate(null);
      setSelectedTime(null);
      setSelectedCompanyProduct(null);
    }
  };

  const onProductSelected = async (product: CompanyProduct) => {
    setSelectedCompanyProduct(product);
    setAttendeesCount(product.minimum_attendees);

    if (onSubStepDone) {
      onSubStepDone('product');
    }

    if (flow.products_before_calendar) {
      setActiveStartDate(undefined);
      nextStep();
    } else if (!product.attendees_before_time) {
      setIsLoadingAvailability(true);

      const {data: availableTimesData} = await fetchAvailableTimes(product.id, formatDate(selectedDate!));

      setAvailableTimes(availableTimesData);
      setIsLoadingAvailability(false);
    }
  };

  const isTimetableProductDateDisabled = (
    companyProduct: CompanyProduct,
    dayOfTheWeek: DayOfWeek,
    formattedDate: string
  ): boolean => {
    if (companyProduct.availability[dayOfTheWeek] === null) {
      if (companyProduct.calendar_type === 'auto') {
        if (companyProduct.custom_dates.indexOf(formattedDate) === -1) {
          return true;
        }
      } else {
        return true;
      }
    }

    if (companyProduct.calendar_type === 'manual' && companyProduct.custom_dates.indexOf(formattedDate) === -1) {
      return true;
    }

    return companyProduct.disabled_dates.indexOf(formattedDate) !== -1;
  };

  const isProductDateDisabled = (companyProduct: CompanyProduct, dayOfTheWeek: DayOfWeek, formattedDate: string) => {
    if (companyProduct.availability[dayOfTheWeek] === null) {
      return true;
    }

    if (companyProduct.disabled_dates.indexOf(formattedDate) !== -1) {
      return true;
    }

    return companyProduct.calendar_type === 'manual' && companyProduct.custom_dates.indexOf(formattedDate) === -1;
  };

  const isDateDisabled = ({date, view}: {date: Date; view: string}) => {
    if (view === 'month') {
      const dayOfTheWeek = numberToDayOfTheWeek(date.getDay() as any);

      if (
        flow.products_before_calendar &&
        flow.is_timetable &&
        selectedCategory !== null &&
        selectedCategory.category_product_ids
      ) {
        const formattedDate = formatDate(date);

        return companyProducts
          .filter((companyProduct) => selectedCategory.category_product_ids!.indexOf(companyProduct.id) !== -1)
          .every((companyProduct) => isTimetableProductDateDisabled(companyProduct, dayOfTheWeek, formattedDate));
      }

      if (companyProducts.length !== 1 && !flow.products_before_calendar) {
        const formattedDate = formatDate(date);

        return companyProducts.every((companyProduct) =>
          isProductDateDisabled(companyProduct, dayOfTheWeek, formattedDate)
        );
      }

      if (selectedCompanyProduct !== null) {
        if (selectedCompanyProduct.availability[dayOfTheWeek] === null) {
          if (selectedCompanyProduct.calendar_type === 'auto') {
            const formattedDate = formatDate(date);

            if (selectedCompanyProduct.custom_dates.indexOf(formattedDate) === -1) {
              return true;
            }
          } else {
            return true;
          }
        }

        if (selectedCompanyProduct.calendar_type === 'manual') {
          const formattedDate = formatDate(date);

          if (selectedCompanyProduct.custom_dates.indexOf(formattedDate) === -1) {
            return true;
          }
        }
      }

      if (selectedCompanyProduct !== null) {
        const formattedDate = formatDate(date);

        return selectedCompanyProduct.disabled_dates.indexOf(formattedDate) !== -1;
      }
    }

    return false;
  };

  const maxDate = useMemo(() => {
    if (step === 'date') {
      if (selectedCompanyProduct === null && flow.is_timetable && selectedCategory !== null) {
        const selectedCategoryProducts = companyProducts.filter(
          (companyProduct) => selectedCategory.category_product_ids!.indexOf(companyProduct.id) !== -1
        );

        const dates = selectedCategoryProducts.map((product) => new Date(product.maximum_date).getTime());

        const maxDateTimestamp = Math.max(...dates);
        return new Date(maxDateTimestamp);
      }

      if (
        selectedCompanyProduct === null &&
        companyProducts.length > 1 &&
        (!flow.products_before_calendar || flow.is_timetable)
      ) {
        const dates = companyProducts.map((product) => new Date(product.maximum_date).getTime());

        const maxDateTimestamp = Math.max(...dates);
        return new Date(maxDateTimestamp);
      }

      if (selectedCompanyProduct !== null) {
        return new Date(selectedCompanyProduct.maximum_date);
      }
    }
  }, [step, selectedCompanyProduct, selectedCategory]);

  const minDate = useMemo(() => {
    if (step === 'date') {
      if (selectedCompanyProduct === null && flow.is_timetable && selectedCategory !== null) {
        const selectedCategoryProducts = companyProducts.filter(
          (companyProduct) => selectedCategory.category_product_ids!.indexOf(companyProduct.id) !== -1
        );

        const dates = selectedCategoryProducts.map((product) => new Date(product.minimum_date).getTime());

        const minDateTimestamp = Math.min(...dates);
        return new Date(minDateTimestamp);
      }

      if (
        selectedCompanyProduct === null &&
        companyProducts.length > 1 &&
        (!flow.products_before_calendar || flow.is_timetable)
      ) {
        const dates = companyProducts.map((product) => new Date(product.minimum_date).getTime());

        const minDateTimestamp = Math.min(...dates);
        return new Date(minDateTimestamp);
      }

      if (selectedCompanyProduct !== null) {
        return new Date(selectedCompanyProduct.minimum_date);
      }

      return new Date();
    }
  }, [step, selectedCompanyProduct, selectedCategory]);

  const onCategoryChangeClick = () => {
    if (flow.products_before_calendar && onBack) {
      onBack();
    }

    if (flow.is_timetable && productsWithAvailability !== null) {
      setIsLoadingAvailability(true);
      setProductsWithAvailability(null);
    }

    setSelectedCategory(null);
    setSelectedCompanyProduct(null);
    setSelectedAttendees(null);
    setSelectedTime(null);
  };

  const onProductChangeClick = () => {
    setSelectedCompanyProduct(null);
    setSelectedTime(null);

    if (flow.products_before_calendar && selectedCompanyProduct !== null && step === 'date' && onBack) {
      onBack();
    }

    if (!flow.is_timetable && selectedAttendees !== null) {
      setSelectedAttendees(null);
    }
  };

  const onAttendeesChangeClick = () => {
    setSelectedAttendees(null);
    setSelectedTime(null);

    if (flow.is_timetable && selectedCompanyProduct !== null) {
      setSelectedCompanyProduct(null);
    }
  };

  const onDateChangeClick = () => {
    setSelectedDate(null);
    setSelectedAttendees(null);
    setSelectedTime(null);

    if (companyProducts.length !== 1 && !flow.products_before_calendar && !flow.is_timetable) {
      setSelectedCategory(null);
      setSelectedCompanyProduct(null);
    } else if (flow.is_timetable) {
      if (selectedCompanyProduct !== null) {
        setSelectedCompanyProduct(null);
      }
    }
  };

  const onTimeChangeClick = () => {
    setSelectedTime(null);

    if (selectedCompanyProduct !== null && flow.is_timetable) {
      setSelectedCompanyProduct(null);
    }
  };

  const onDateSelected = async (date: Date) => {
    setSelectedDate(date);
    setActiveStartDate(date);

    if (onSubStepDone) {
      onSubStepDone('date');
    }

    if (flow.is_timetable) {
      const requiresCategory = companyProducts.every((product) => product.categories.length !== 0);

      if (requiresCategory && selectedCategory === null) {
        return;
      }

      if (selectedCategory !== null) {
        const dayOfTheWeek = numberToDayOfTheWeek(date.getDay() as any);
        const formattedDate = formatDate(date);

        const dateForCategoryUnavailable = companyProducts
          .filter((companyProduct) => selectedCategory.category_product_ids!.indexOf(companyProduct.id) !== -1)
          .every((companyProduct) => isTimetableProductDateDisabled(companyProduct, dayOfTheWeek, formattedDate));

        if (dateForCategoryUnavailable) {
          setSelectedCategory(null);
          return;
        }
      }

      const requiresAttendees =
        selectedCategory !== null
          ? companyProducts
              .filter((companyProduct) => selectedCategory.category_product_ids!.indexOf(companyProduct.id) !== -1)
              .some((product) => product.attendees_before_time)
          : companyProducts.some((product) => product.attendees_before_time);

      if (requiresAttendees) {
        return;
      }

      setIsLoadingAvailability(true);
      setProductsWithAvailability(null);

      const {data: productsWithAvailabilityData} = await fetchFlowProductsWithAvailability(flow.id, {
        date: formatDate(date),
        category_id: selectedCategory?.id,
      });

      setProductsWithAvailability(productsWithAvailabilityData);
      setIsLoadingAvailability(false);

      return;
    }

    if (selectedCompanyProduct === null) {
      return;
    }

    if (companyProducts.length > 1 && !flow.products_before_calendar) {
      return;
    }

    if (selectedCompanyProduct.attendees_before_time) {
      return;
    }

    setIsLoadingAvailability(true);

    const {data: availableTimesData} = await fetchAvailableTimes(selectedCompanyProduct.id, formatDate(date));

    setAvailableTimes(availableTimesData);
    setIsLoadingAvailability(false);
  };

  const onDateTimeSubmit = () => {
    if (productBookingData !== null && discountCode) {
      setSelectedTime({
        ...selectedTime!,
        final_price: selectedTime!.price,
      });

      setDiscountCodeApplied(false);

      clearDiscountCodeError();
    }

    nextStep();

    const updatedBookingData: ProductBookingData = {
      type: embedded ? 'embedded_flow' : 'redirect_flow',
      flow_id: flow.id,
      date: formatDate(selectedDate!),
      time: selectedTime!.from,
      discount_code: undefined,
    };

    if (productBookingData === null) {
      setProductBookingData(updatedBookingData);
    } else {
      setProductBookingData({
        ...productBookingData,
        ...updatedBookingData,
      });
    }
  };

  const onContactSubmit = (e: any) => {
    e.preventDefault();

    const details: ProductBookingData['details'] = {} as ProductBookingData['details'];

    let isValid = true;
    let validationErrors = {};

    const email = e.target.email.value;

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      isValid = false;

      validationErrors = {
        email: t('invalid_email'),
      };
    } else {
      const emailMistypeCheckResult = checkCommonEmailMistypes(email);

      if (!emailMistypeCheckResult.isValid) {
        isValid = false;

        validationErrors = {
          email: `${t(
            'email_mistype'
          )} ${emailMistypeCheckResult.correctPart.toLowerCase()}${emailMistypeCheckResult.correctedPart.toUpperCase()}?`,
        };
      }
    }

    for (const field of selectedCompanyProduct!.contact_form) {
      if (field.name !== 'email') {
        const fieldName = field.name;
        const fieldData = e.target[field.name];

        if (fieldData.value !== '') {
          if (fieldData.type === 'tel') {
            fieldData.value = fieldData.value.replaceAll(' ', '');

            if (!/^\+?\d{8,15}$/.test(fieldData.value)) {
              isValid = false;

              validationErrors = {
                ...validationErrors,
                [fieldName]: t('invalid_phone'),
              };
            }
          } else if (fieldData.type === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(fieldData.value)) {
            isValid = false;

            validationErrors = {
              ...validationErrors,
              [fieldName]: t('invalid_email'),
            };
          }
        }

        if (field.type === 'checkbox') {
          details[fieldName] = fieldData.checked ? 'y' : 'n';
        } else {
          details[fieldName] = fieldData.value || null;
        }
      }
    }

    if (discountCode && !discountCodeApplied) {
      isValid = false;
      validationErrors = {
        ...validationErrors,
        discount_code: t('discount_code_not_applied'),
      };
    }

    if (!isValid) {
      setContactErrors(validationErrors);

      return;
    }

    setProductBookingData({
      ...productBookingData!,
      email,
      details,
    });

    nextStep();
  };

  const onSummarySubmit = async (advancePayment: boolean = false, payLater: boolean = false) => {
    setError(null);
    setWaitingForRedirect(true);

    try {
      const {data: createdBookingData} = await createProductBooking(selectedCompanyProduct!.id, {
        ...productBookingData!,
        advance_payment: advancePayment,
        pay_later: payLater,
      });

      setProductBooking(createdBookingData);

      sendEvent('booking_submitted', {
        product: {
          id: createdBookingData.product_id,
          title: createdBookingData.product_title,
        },
        booking: {
          price: createdBookingData.price,
          advance_price: createdBookingData.advance_price,
        },
      });

      if (flow.with_checkout && createdBookingData.price !== null && createdBookingData.checkout_data !== null) {
        onCheckoutStart(createdBookingData);
      } else {
        nextStep();

        sendEvent('booking_success', {
          product: {
            id: createdBookingData.product_id,
            title: createdBookingData.product_title,
          },
          booking: {
            price: createdBookingData.price,
            advance_price: createdBookingData.advance_price,
          },
        });
      }
    } catch (e) {
      const parsedError = parseError(e);

      if (parsedError.code === 'time_is_unavailable') {
        const {data: availableTimesData} = await fetchAvailableTimes(
          selectedCompanyProduct!.id,
          formatDate(selectedDate!),
          selectedAttendees ?? undefined
        );

        setAvailableTimes(availableTimesData);
      }

      setWaitingForRedirect(false);
      setError(parsedError.message);
    }
  };

  const onAttendeesConfirm = async (attendeesCount: number) => {
    if (productBookingData === null) {
      setProductBookingData({
        type: embedded ? 'embedded_flow' : 'redirect_flow',
        flow_id: flow.id,
        attendees_count: attendeesCount!,
      });
    } else {
      setProductBookingData({
        ...productBookingData,
        attendees_count: attendeesCount!,
      });
    }

    setProductBookingData({
      ...productBookingData!,
      attendees_count: attendeesCount!,
    });

    setSelectedAttendees(attendeesCount);

    if (flow.is_timetable && companyProducts.some((product) => product.attendees_before_time)) {
      setIsLoadingAvailability(true);
      setProductsWithAvailability(null);

      const {data: productsWithAvailabilityData} = await fetchFlowProductsWithAvailability(flow.id, {
        date: formatDate(selectedDate!),
        attendees_count: attendeesCount,
        category_id: selectedCategory?.id,
      });

      setProductsWithAvailability(productsWithAvailabilityData);

      setIsLoadingAvailability(false);

      return;
    }

    if (!selectedCompanyProduct!.attendees_before_time) {
      nextStep();
    } else {
      setAvailableTimes(null);
      setIsLoadingAvailability(true);

      const {data: availableTimesData} = await fetchAvailableTimes(
        selectedCompanyProduct!.id,
        formatDate(selectedDate!),
        attendeesCount!
      );

      setAvailableTimes(availableTimesData);
      setIsLoadingAvailability(false);
    }
  };

  const onTimetableTimeSelected = (productId: string, timeData: ProductAvailableTime) => {
    const product = companyProducts.find((product) => product.id === productId);

    setSelectedCompanyProduct(product as CompanyProduct);
    setSelectedTime({
      ...timeData,
      final_price: timeData.price,
    });
  };

  const renderAttendeesSelect = () => {
    if (flow.is_timetable) {
      let minAttendees = companyProducts.length > 0 ? companyProducts[0].minimum_attendees : 0;
      let maxAttendees = companyProducts.length > 0 ? companyProducts[0].maximum_attendees : 0;

      companyProducts.forEach((product) => {
        if (selectedCategory !== null) {
          if (selectedCategory.category_product_ids?.indexOf(product.id) !== -1) {
            minAttendees = Math.min(minAttendees, product.minimum_attendees);
            maxAttendees = Math.max(maxAttendees, product.maximum_attendees);
          }
        } else {
          minAttendees = Math.min(minAttendees, product.minimum_attendees);
          maxAttendees = Math.max(maxAttendees, product.maximum_attendees);
        }
      });

      return (
        <>
          {step !== 'attendees' && (
            <>
              {selectedCategory !== null && flow.products_before_calendar && (
                <SelectedCategory selectedCategory={selectedCategory} onCategoryChangeClick={onCategoryChangeClick} />
              )}
              <SelectedDate selectedDate={selectedDate!} onDateChangeClick={onDateChangeClick} />
              {selectedCategory !== null && !flow.products_before_calendar && (
                <SelectedCategory selectedCategory={selectedCategory} onCategoryChangeClick={onCategoryChangeClick} />
              )}
            </>
          )}
          <AttendeesSelection
            onAttendeesConfirm={onAttendeesConfirm}
            attendeesCount={attendeesCount ?? minAttendees}
            setAttendeesCount={setAttendeesCount}
            minimum={minAttendees}
            maximum={maxAttendees}
            title={
              (flow.is_timetable && flow.texts?.attendees_count) ||
              selectedCompanyProduct?.flow_texts.attendees_count ||
              t('attendees_count')
            }
          />
        </>
      );
    }

    return (
      <div>
        {selectedCompanyProduct!.attendees_before_time && (
          <>
            {selectedCategory !== null && flow.products_before_calendar && (
              <SelectedCategory selectedCategory={selectedCategory} onCategoryChangeClick={onCategoryChangeClick} />
            )}
            {selectedCompanyProduct !== null && flow.products_before_calendar && companyProducts.length > 1 && (
              <SelectedProduct
                selectedCompanyProduct={selectedCompanyProduct!}
                onProductChangeClick={onProductChangeClick}
              />
            )}
            <SelectedDate selectedDate={selectedDate!} onDateChangeClick={onDateChangeClick} />
            {companyProducts.length > 1 && !flow.products_before_calendar && (
              <>
                {selectedCategory !== null && (
                  <SelectedCategory selectedCategory={selectedCategory} onCategoryChangeClick={onCategoryChangeClick} />
                )}
                <SelectedProduct
                  selectedCompanyProduct={selectedCompanyProduct!}
                  onProductChangeClick={onProductChangeClick}
                />
              </>
            )}
          </>
        )}
        <AttendeesSelection
          attendeesCount={attendeesCount!}
          setAttendeesCount={setAttendeesCount}
          onAttendeesConfirm={onAttendeesConfirm}
          minimum={selectedCompanyProduct!.minimum_attendees}
          maximum={selectedCompanyProduct!.maximum_attendees}
          title={selectedCompanyProduct!.flow_texts.attendees_count}
        />
      </div>
    );
  };

  const renderDateSelect = () => (
    <div className='max-w-2xl mx-auto'>
      {selectedCategory !== null && flow.products_before_calendar && (
        <SelectedCategory selectedCategory={selectedCategory} onCategoryChangeClick={onCategoryChangeClick} />
      )}
      {selectedCompanyProduct !== null && flow.products_before_calendar && companyProducts.length > 1 && (
        <SelectedProduct selectedCompanyProduct={selectedCompanyProduct} onProductChangeClick={onProductChangeClick} />
      )}
      {flow.texts?.calendar && <p className='mb-4 text-center'>{flow.texts.calendar}</p>}
      <Calendar
        locale={language}
        minDetail='year'
        maxDate={maxDate}
        tileDisabled={isDateDisabled}
        minDate={minDate}
        onClickDay={onDateSelected}
        next2Label={null}
        defaultActiveStartDate={activeStartDate ?? (flow.month_by_minimum_date ? minDate : undefined)}
        prev2Label={null}
        className={flow.is_timetable ? 'max-w-[33rem]' : undefined}
      />
    </div>
  );

  const renderTimeSelect = () => (
    <div className='flex flex-col items-center overflow-y-auto'>
      {selectedCategory !== null && flow.products_before_calendar && (
        <SelectedCategory selectedCategory={selectedCategory} onCategoryChangeClick={onCategoryChangeClick} />
      )}
      {flow.products_before_calendar && companyProducts.length > 1 && (
        <SelectedProduct selectedCompanyProduct={selectedCompanyProduct!} onProductChangeClick={onProductChangeClick} />
      )}
      <SelectedDate selectedDate={selectedDate!} onDateChangeClick={onDateChangeClick} />
      {companyProducts.length > 1 && !flow.products_before_calendar && (
        <>
          {selectedCategory !== null && (
            <SelectedCategory selectedCategory={selectedCategory} onCategoryChangeClick={onCategoryChangeClick} />
          )}
          {flow.is_timetable && selectedAttendees !== null && (
            <SelectedAttendees
              title={flow.texts?.attendees_count || t('attendees_count')}
              onAttendeesChangeClick={onAttendeesChangeClick}
              selectedAttendees={selectedAttendees}
            />
          )}
          {(selectedCategory === null || selectedCategory.title !== selectedCompanyProduct!.title) && (
            <SelectedProduct
              selectedCompanyProduct={selectedCompanyProduct!}
              onProductChangeClick={onProductChangeClick}
            />
          )}
        </>
      )}
      {flow.products_before_calendar && flow.is_timetable && selectedCompanyProduct && (
        <>
          {selectedAttendees !== null && (
            <SelectedAttendees
              title={
                (flow.is_timetable && flow.texts?.attendees_count) ||
                selectedCompanyProduct!.flow_texts.attendees_count ||
                t('attendees_count')
              }
              onAttendeesChangeClick={onAttendeesChangeClick}
              selectedAttendees={selectedAttendees!}
            />
          )}

          <SelectedProduct
            selectedCompanyProduct={selectedCompanyProduct!}
            onProductChangeClick={onProductChangeClick}
          />
        </>
      )}
      {selectedCompanyProduct!.attendees_before_time && !flow.is_timetable && (
        <SelectedAttendees
          title={
            (flow.is_timetable && flow.texts?.attendees_count) ||
            selectedCompanyProduct!.flow_texts.attendees_count ||
            t('attendees_count')
          }
          onAttendeesChangeClick={onAttendeesChangeClick}
          selectedAttendees={selectedAttendees!}
        />
      )}
      {selectedTime === null && (
        <>
          {isLoadingAvailability && <div>{t('calendar.loading_times')}</div>}
          {!isLoadingAvailability && availableTimes !== null && availableTimes.length > 0 && (
            <>
              {availableTimes.every(
                (availableTime) => !availableTime.available && !availableTime.attendees_mismatch
              ) ? (
                <p className='text-lg text-error text-center'>{t('no_available_times')}</p>
              ) : (
                <div className='flex flex-wrap gap-2 justify-center max-w-xs lg:max-w-lg lg:tall-lg:overflow-y-auto lg:tall:overflow-y-visible tall:overflow-y-auto w-full p-1'>
                  {availableTimes
                    .filter((availableTime) => !flow.hide_disabled_times || availableTime.available)
                    .map((availableTime) => (
                      <button
                        key={availableTime.from}
                        className={`w-full lg:w-auto btn btn-outline btn-primary lg:min-w-[132px] flex-col btn-primary-outline-fix ${
                          availableTimes.some((availableTime) => availableTime.available_slots) ? 'h-[4rem]' : ''
                        }`}
                        disabled={!availableTime.available}
                        onClick={() => setSelectedTime({...availableTime, final_price: availableTime.price})}
                      >
                        <div className='flex flex-col gap-1'>
                          {availableTime.from}
                          {!flow.hide_end_times && ` - ${availableTime.to}`}
                          {availableTime.price !== null && <div>{availableTime.price}€</div>}
                          {availableTime.available_slots && (
                            <div
                              className={`lowercase first-letter:uppercase  ${
                                availableTime.attendees_mismatch ? 'text-error' : 'text-secondary'
                              }`}
                            >
                              {t('slots_left', {count: availableTime.available_slots})}
                            </div>
                          )}
                        </div>
                      </button>
                    ))}
                </div>
              )}
              {selectedCompanyProduct!.flow_texts?.time_select_additional && (
                <p className='mt-4 font-medium'>{selectedCompanyProduct!.flow_texts.time_select_additional}</p>
              )}
            </>
          )}
          {!isLoadingAvailability && availableTimes !== null && availableTimes.length === 0 && (
            <div className='text-lg text-error text-center'>{t('no_available_times')}</div>
          )}
        </>
      )}
    </div>
  );

  const onContactFormInputChange = (e: any) => {
    if (contactErrors[e.target.name]) {
      const updatedContactErrors = {...contactErrors};
      delete updatedContactErrors[e.target.name];

      setContactErrors(updatedContactErrors);
    }
  };

  const renderDateTimeSelect = () => {
    if (selectedDate === null) {
      return renderDateSelect();
    }

    if (selectedCompanyProduct === null && !flow.products_before_calendar && !flow.is_timetable) {
      return (
        <ProductSelection
          flow={flow}
          selectedDate={selectedDate}
          onDateChangeClick={onDateChangeClick}
          companyProducts={companyProducts}
          onProductSelected={onProductSelected}
          onCategorySelected={onCategorySelected}
          selectedCategory={selectedCategory}
        />
      );
    }

    if (selectedCompanyProduct !== null && selectedCompanyProduct.attendees_before_time && selectedAttendees === null) {
      return renderAttendeesSelect();
    }

    if (selectedCompanyProduct === null && flow.is_timetable) {
      const requiresCategory = companyProducts.every((product) => product.categories.length !== 0);

      if (!flow.products_before_calendar && requiresCategory && selectedCategory === null) {
        return (
          <ProductSelection
            flow={flow}
            selectedDate={selectedDate}
            onDateChangeClick={onDateChangeClick}
            companyProducts={companyProducts}
            onProductSelected={onProductSelected}
            onCategorySelected={onCategorySelected}
            selectedCategory={selectedCategory}
          />
        );
      }

      const requiresAttendees =
        selectedCategory !== null
          ? companyProducts
              .filter((companyProduct) => selectedCategory.category_product_ids!.indexOf(companyProduct.id) !== -1)
              .some((product) => product.attendees_before_time)
          : companyProducts.some((product) => product.attendees_before_time);

      if (requiresAttendees && selectedAttendees === null) {
        return renderAttendeesSelect();
      }

      return (
        <>
          {selectedCategory !== null && flow.products_before_calendar && (
            <SelectedCategory selectedCategory={selectedCategory} onCategoryChangeClick={onCategoryChangeClick} />
          )}
          <SelectedDate selectedDate={selectedDate} onDateChangeClick={onDateChangeClick} />
          {selectedCategory !== null && !flow.products_before_calendar && (
            <SelectedCategory selectedCategory={selectedCategory} onCategoryChangeClick={onCategoryChangeClick} />
          )}
          {requiresAttendees && (
            <SelectedAttendees
              title={flow.texts?.attendees_count || t('attendees_count')}
              onAttendeesChangeClick={onAttendeesChangeClick}
              selectedAttendees={selectedAttendees!}
            />
          )}
          {isLoadingAvailability ? (
            <Loading />
          ) : (
            <ProductsTimeTable
              productsWithAvailability={productsWithAvailability!}
              layout={flow.timetable_type!}
              onTimeSelected={onTimetableTimeSelected}
              flow={flow}
              embedded={embedded}
            />
          )}
        </>
      );
    }

    if (
      selectedCompanyProduct !== null &&
      (!selectedCompanyProduct!.attendees_before_time || selectedAttendees !== null)
    ) {
      return (
        <>
          {renderTimeSelect()}
          {selectedTime !== null && (
            <>
              <SelectedTime
                selectedTime={selectedTime}
                onTimeChangeClick={onTimeChangeClick}
                hideEndTime={flow.hide_end_times}
              />
              <div className='text-center'>
                <button className='max-w-2xl btn btn-primary w-full mt-4' onClick={onDateTimeSubmit}>
                  {t('next')}
                </button>
              </div>
            </>
          )}
        </>
      );
    }
  };

  const clearDiscountCodeError = () => {
    if (contactErrors['discount_code']) {
      const updatedContactErrors = {...contactErrors};
      delete updatedContactErrors['discount_code'];

      setContactErrors(updatedContactErrors);
    }
  };

  const onDiscountCodeApplied = (discountCode: string, price: string) => {
    clearDiscountCodeError();

    setProductBookingData({
      ...productBookingData!,
      discount_code: discountCode,
    });

    setSelectedTime({
      ...selectedTime!,
      final_price: price,
    });

    setDiscountCodeApplied(true);
  };

  const onDiscountCodeRemoved = () => {
    clearDiscountCodeError();

    setProductBookingData({
      ...productBookingData!,
      discount_code: undefined,
    });

    setSelectedTime({
      ...selectedTime!,
      final_price: selectedTime!.price,
    });

    setDiscountCodeApplied(false);
  };

  const onDiscountCodeChanged = (discountCode: string) => {
    clearDiscountCodeError();
    setDiscountCode(discountCode);
  };

  const renderContactForm = () => {
    const renderField = (field: ContactFormField) => {
      if (field.type === 'textarea') {
        return (
          <div key={field.name} className='flex'>
            <textarea
              name={field.name}
              required={field.required}
              className='textarea textarea-primary focus:outline-none focus:border-2 focus:border-primary w-full min-h-[4.5rem]'
              placeholder={field.label}
              onChange={onContactFormInputChange}
              defaultValue={productBookingData!.details ? productBookingData!.details[field.name] : ''}
            />
            {contactErrors[field.name] && (
              <p className='mt-1 text-error text-sm font-medium'>{contactErrors[field.name]}</p>
            )}
          </div>
        );
      }

      if (field.type === 'checkbox') {
        return (
          <div key={field.name} className='flex gap-2 items-center'>
            <input
              name={field.name}
              type={field.type}
              required={field.required}
              className='checkbox checkbox-primary'
              onChange={onContactFormInputChange}
              defaultChecked={false}
            />
            <div className='text-sm'>{field.label}</div>
          </div>
        );
      }

      if (field.type === 'number') {
        return (
          <div key={field.name}>
            <input
              name={field.name}
              type='number'
              required={field.required}
              className='input input-primary focus:outline-none focus:border-2 focus:border-primary w-full'
              placeholder={field.label}
              min={field.min || 0}
              max={field.max}
              onChange={onContactFormInputChange}
              defaultValue={productBookingData!.details ? productBookingData!.details[field.name] : ''}
            />
          </div>
        );
      }

      if (field.type === 'tel') {
        return (
          <PhoneField
            name={field.name}
            label={field.label}
            required={field.required}
            defaultValue={productBookingData!.details ? productBookingData!.details[field.name] : undefined}
            onChange={onContactFormInputChange}
            error={contactErrors[field.name]}
            defaultPhoneCountryData={field.default_phone_country}
          />
        );
      }

      return (
        <div key={field.name}>
          <input
            name={field.name}
            type={field.type}
            required={field.required}
            className='input input-primary focus:outline-none focus:border-2 focus:border-primary w-full'
            placeholder={field.label}
            onChange={onContactFormInputChange}
            defaultValue={
              field.name === 'email'
                ? productBookingData!.email
                : productBookingData!.details
                ? productBookingData!.details[field.name]
                : ''
            }
          />
          {contactErrors[field.name] && (
            <p className='mt-1 text-error text-sm font-medium'>{contactErrors[field.name]}</p>
          )}
        </div>
      );
    };

    return (
      <div>
        <form className='max-w-2xl form-control flex flex-col gap-4 mx-auto' onSubmit={onContactSubmit}>
          {selectedCompanyProduct!.contact_form.sort((a: any, b: any) => a.order - b.order).map(renderField)}
          {selectedCompanyProduct!.with_discount_codes && (
            <DiscountCodeInput
              discountCode={discountCode}
              onDiscountCodeChanged={onDiscountCodeChanged}
              onCodeApplied={onDiscountCodeApplied}
              onCodeRemoved={onDiscountCodeRemoved}
              discountCodeApplied={discountCodeApplied}
              setDiscountCodeApplied={setDiscountCodeApplied}
              error={contactErrors['discount_code']}
              companyProduct={selectedCompanyProduct!}
              date={formatDate(selectedDate!)}
              time={selectedTime!.from}
              attendeesCount={selectedAttendees}
            />
          )}
          <div className='text-center'>
            <button type='submit' className='btn btn-primary w-full mt-4'>
              {t('next')}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const finalPriceWithDiscount = useMemo(() => {
    if (selectedTime === null) {
      return null;
    }

    if (selectedTime.final_price !== selectedTime.price) {
      return (
        <span>
          <s className='text-gray-400 line-through'>{selectedTime.price}</s> {selectedTime.final_price}€
        </span>
      );
    }

    return <span>{selectedTime.final_price}€</span>;
  }, [selectedTime]);

  const onCategorySelected = async (category: CompanyProductCategory | null) => {
    setSelectedCategory(category);

    if (onSubStepDone) {
      onSubStepDone('category');
    }

    if (category !== null && flow.is_timetable) {
      if (flow.products_before_calendar) {
        nextStep();
      } else {
        const requiresAttendees = companyProducts
          .filter((companyProduct) => category.category_product_ids!.indexOf(companyProduct.id) !== -1)
          .some((product) => product.attendees_before_time);

        if (requiresAttendees) {
          return;
        } else {
          const {data: productsWithAvailabilityData} = await fetchFlowProductsWithAvailability(flow.id, {
            date: formatDate(selectedDate!),
            category_id: category.id,
          });

          setProductsWithAvailability(productsWithAvailabilityData);
          setIsLoadingAvailability(false);
        }
      }
    }
  };

  const renderStep = () => {
    if (step === 'product') {
      if (
        flow.products_before_calendar &&
        flow.is_timetable &&
        companyProducts.every((product) => product.categories.length !== 0)
      ) {
        return (
          <ProductSelection
            flow={flow}
            selectedDate={selectedDate}
            onDateChangeClick={onDateChangeClick}
            companyProducts={companyProducts}
            onProductSelected={onProductSelected}
            onCategorySelected={onCategorySelected}
            selectedCategory={selectedCategory}
          />
        );
      }

      return (
        <ProductSelection
          flow={flow}
          selectedDate={selectedDate}
          onDateChangeClick={onDateChangeClick}
          companyProducts={companyProducts}
          onProductSelected={onProductSelected}
          onCategorySelected={onCategorySelected}
          selectedCategory={selectedCategory}
        />
      );
    }

    if (step === 'date') {
      return renderDateTimeSelect();
    }

    if (step === 'contact') {
      return renderContactForm();
    }

    if (step === 'attendees') {
      return renderAttendeesSelect();
    }

    if (step === 'summary') {
      return (
        <Summary
          flow={flow}
          selectedTime={selectedTime}
          selectedCompanyProduct={selectedCompanyProduct!}
          waitingForRedirect={waitingForRedirect}
          onSummarySubmit={onSummarySubmit}
          productBookingData={productBookingData!}
          finalPriceWithDiscount={finalPriceWithDiscount}
        />
      );
    }

    if (step === 'success') {
      return (
        <div>
          <p className='text-success text-center mb-4'>{selectedCompanyProduct!.flow_texts.success}</p>
          {productBooking !== null && (
            <BookingStateWithDetails bookingData={productBooking} companyProduct={selectedCompanyProduct!} />
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {stepId !== initialStep && step !== 'success' && embedded && flow.embedding_settings.modal && (
        <button
          className='btn btn-sm btn-circle btn-outline border-base-200 absolute left-2 top-2 z-10'
          onClick={() => onBack !== null && onBack()}
        >
          <IoChevronBackSharp size={16} />
        </button>
      )}
      {['date', 'contact', 'attendees', 'summary'].indexOf(step) !== -1 && (
        <>
          <div className='text-center mb-4 relative max-w-2xl w-full mx-auto'>
            {stepId !== initialStep && step !== 'success' && embedded && !flow.embedding_settings.modal && (
              <button
                className='btn btn-sm btn-circle btn-outline border-base-200 absolute left-0 top-0 z-10'
                onClick={() => onBack !== null && onBack()}
              >
                <IoChevronBackSharp size={16} />
              </button>
            )}
            {stepId !== initialStep && step !== 'success' && !embedded && (
              <button
                className='btn btn-sm btn-circle btn-outline border-base-200 absolute top-0 left-0 z-10'
                onClick={() => onBack !== null && onBack()}
              >
                <IoChevronBackSharp size={16} />
              </button>
            )}
            <ul className='steps lg:w-5/6 w-full mx-auto'>
              <li data-content='' className='step step-secondary text-secondary-content relative'>
                <IoCalendarNumberSharp size={18} className='z-10 absolute top-1/2 -translate-y-1/2' />
              </li>
              <li
                data-content=''
                className={`step relative ${stepId > 2 ? 'step-secondary text-secondary-content' : 'text-secondary'}`}
              >
                <AiOutlineForm size={18} className='z-10 absolute top-1/2 -translate-y-1/2' />
              </li>
              <li
                data-content=''
                className={`step relative ${stepId > 4 ? 'step-secondary text-secondary-content' : 'text-secondary'}`}
              >
                <IoCheckmarkSharp size={18} className='z-10 absolute top-1/2 -translate-y-1/2' />
              </li>
            </ul>
          </div>
          {selectedTime !== null && ['contact', 'attendees'].indexOf(step) !== -1 && (
            <div className='mb-4 text-center text-lg font-medium'>
              {formatDate(selectedDate!)} {selectedTime.from}{' '}
              {selectedTime.final_price !== null && finalPriceWithDiscount}
            </div>
          )}
        </>
      )}
      {renderStep()}
      {error !== null && <div className='mt-2 text-error text-center font-medium'>{error}</div>}
    </>
  );
};

export default Booking;
