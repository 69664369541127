import api, {ApiPromise} from '../services/api';
import {BookingData} from './BookingAPI.ts';

export type DayOfWeek = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export interface CompanyProduct {
  id: string;
  title: string;
  availability: Record<
    DayOfWeek,
    {
      from: string;
      to: string;
    } | null
  >;
  minimum_attendees: number;
  maximum_attendees: number;
  attendees_before_time: boolean;
  with_attendees: boolean;
  disabled_dates: string[];
  custom_dates: string[];
  calendar_type: 'auto' | 'manual';
  terms_url: string;
  contact_form: ContactFormField[];
  flow_texts: {
    attendees_count: string;
    success: string;
    checkout_success: string;
    checkout_cancel: string;
    summary_additional?: string | null;
    time_select_additional?: string | null;
  };
  categories: CompanyProductCategory[];
  maximum_date: string;
  minimum_date: string;
  only_advance_payment: boolean;
  with_discount_codes: boolean;
  disable_checkout: boolean;
}

export interface CompanyProductCategory {
  id: string;
  title: string;
  description: string | null;
  category_product_ids?: string[];
}

export interface ContactFormField {
  order: number;
  name: string;
  label: string;
  type: 'email' | 'tel' | 'text' | 'textarea' | 'checkbox' | 'number';
  required: boolean;
  min?: number;
  max?: number;
  default_phone_country?: {
    dial_code: string;
    country_code: string;
  }
}

export interface ProductAvailableTime {
  available: boolean;
  duration: number;
  from: string;
  to: string;
  price: string | null;
  advance_price: string | null;
  final_price: string | null;
  attendees_mismatch?: true;
  available_slots: number | null;
}

export interface ProductBookingData {
  flow_id: string;
  date?: string;
  time?: string;
  attendees_count?: number;
  advance_payment?: boolean;
  email?: string;
  details?: {[key: string]: any} | any;
  type: 'embedded_flow' | 'redirect_flow';
  discount_code?: string;
  pay_later?: boolean;
}

export interface DiscountCodeApplyData {
  discount_code: string;
  date: string;
  time: string;
  attendees_count?: number;
}

export interface DiscountCodeApplyResultData {
  discounted_price: string;
}

export const fetchAvailableTimes = (
  id: string,
  date: string,
  attendeesCount?: number
): ApiPromise<ProductAvailableTime[]> =>
  api.get(`/products/${id}/available-times`, {params: {date, attendees_count: attendeesCount}});

export const createProductBooking = (id: string, data: ProductBookingData): ApiPromise<BookingData> =>
  api.post(`/products/${id}/bookings`, data);

export const applyProductDiscountCode = (id: string, data: DiscountCodeApplyData): ApiPromise<DiscountCodeApplyResultData> =>
  api.put(`/products/${id}/discount-codes/apply`, data);
