import {RiEditFill} from 'react-icons/ri';
import {CompanyProduct} from '../../api/ProductAPI.ts';

interface Props {
  selectedCompanyProduct: CompanyProduct;
  onProductChangeClick: () => void;
}

const SelectedProduct = ({selectedCompanyProduct, onProductChangeClick}: Props) => {
  return (
    <div
      key='selected-product'
      className='selected-booking-item'
      onClick={onProductChangeClick}
    >
      <div className='text-center mx-5'>{selectedCompanyProduct!.title}</div>
      <RiEditFill className='absolute top-1/2 -translate-y-1/2 right-4' />
    </div>
  );
};

export default SelectedProduct;
