import {CompanyProduct, ProductBookingData} from '../api/ProductAPI.ts';
import {BookingData} from '../api/BookingAPI.ts';
import i18n from '../i18n.ts';

export const buildBookingDetails = (companyProduct: CompanyProduct, bookingData: ProductBookingData | BookingData) => {
  const emailLabel = companyProduct.contact_form.find((contactFormField) => contactFormField.name === 'email')!.label;

  return Object.keys(bookingData.details).reduce(
    (acc, detailKey) => {
      const formField = companyProduct.contact_form.find((contactFormField) => contactFormField.name === detailKey);

      if (formField) {
        if (formField.type === 'checkbox' && formField.required) {
          return acc;
        }

        if (!bookingData.details[detailKey]) {
          return acc;
        }

        acc.push({
          label: formField.label,
          value:
            formField.type === 'checkbox' ? i18n.t(bookingData.details[detailKey]) : bookingData.details[detailKey],
        });
      }

      return acc;
    },
    [{label: emailLabel, value: bookingData.email}] as {
      label: string;
      value: string;
    }[]
  );
};
