import api, {ApiPromise} from '../services/api';
import {CompanyProduct, ProductAvailableTime} from './ProductAPI.ts';
import {Style} from '../services/flowColors.ts';

export interface Flow {
  id: string;
  title: string | null;
  embedding_settings: {
    domain: string;
    button: boolean;
    modal: boolean;
    position: 'left' | 'center' | 'right';
    button_text: string | null;
  };
  with_checkout: boolean;
  company_name: string;
  company_legal_data: {
    legal_name: string;
    code: string | null;
    email: string;
    phone: string | null;
    type: 'company' | 'self_employed';
  };
  products_before_calendar: boolean;
  texts: {
    calendar: string | null;
    product_select: string | null;
    attendees_count: string | null;
    pay_later: string | null;
  } | null;
  is_timetable: boolean;
  timetable_type: 'vertical' | 'horizontal' | null;
  with_watermark: boolean;
  style: Style | null;
  pay_later_enabled: boolean;
  logo_url: string | null;
  hide_end_times: boolean;
  hide_disabled_times: boolean;
  always_show_title: boolean;
  month_by_minimum_date: boolean;
}

export interface FlowPaymentData {
  data: string;
  ss1?: string;
  ss2?: string;
}

export interface ProductWithAvailability {
  id: string;
  title: string;
  availability: ProductAvailableTime[];
  minimum_attendees: number;
  maximum_attendees: number;
}

export const fetchFlow = (id: string): ApiPromise<Flow> => api.get(`/flows/${id}`);
export const fetchFlowProducts = (id: string, product_ids?: string[]): ApiPromise<CompanyProduct[]> =>
  api.get(`/flows/${id}/products`, {
    params: {
      product_id: product_ids,
    },
  });
export const confirmFlowPayment = (id: string, data: FlowPaymentData): ApiPromise<any> =>
  api.put(`/flows/${id}/confirm-payment`, {payment_data: data});
export const fetchFlowProductsWithAvailability = (
  id: string,
  params: {
    date: string;
    attendees_count?: number;
    category_id?: string;
  }
): ApiPromise<ProductWithAvailability[]> =>
  api.get(`/flows/${id}/products`, {params: {...params, with_availability: 1}});
