import api, {ApiPromise} from '../services/api';
import {CompanyProduct} from './ProductAPI.ts';

export interface BookingData {
  id: string;
  order_id: string;
  time: string;
  date: string;
  product_id: string;
  product_title: string;
  flow_texts: CompanyProduct['flow_texts'];
  status: 'new' | 'confirmed' | 'completed' | 'canceled';
  price: string | null;
  advance_price: string | null;
  paid_amount: string | null;
  email: string;
  payment_status: 'started' | 'processing' | 'paid' | 'pay_later' | null;
  details?: {[key: string]: any} | any;
  attendees_count: number | null;
  checkout_data: {
    redirect_url: string;
  } | null;
  product_address_data: {
    address: string;
    location?: {
      latitude: number;
      longitude: number;
    };
  } | null;
}

export const fetchBooking = (id: string): ApiPromise<BookingData> => api.get(`/bookings/${id}`);
