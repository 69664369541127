import {ApiError, ApiErrorResponse} from './api';
import i18n from '../i18n.ts';

export const parseError = (e: any): ApiErrorResponse => {
  const errorMap: {[code: string]: string} = {
    time_is_unavailable: i18n.t('errors.time_is_unavailable'),
    access_suspended: i18n.t('errors.access_suspended'),
    invalid_discount_code: i18n.t('errors.invalid_discount_code'),
  };

  if (e instanceof ApiError) {
    return {
      ...e,
      message: errorMap[e.code] || i18n.t('errors.default'),
    }
  }

  if (e.response !== undefined) {
    const errorData = e.response.data as ApiErrorResponse;

    return {
      ...errorData,
      message: errorMap[errorData.code] || i18n.t('errors.default'),
    };
  }

  return {
    code: 'unknown',
    message:  i18n.t('errors.default'),
  };
};
