import {useTranslation} from 'react-i18next';
import {applyProductDiscountCode, CompanyProduct} from '../../api/ProductAPI.ts';
import {IoCloseSharp} from 'react-icons/io5';
import {useEffect, useMemo, useState} from 'preact/compat';
import {parseError} from '../../services/errorHandler.ts';

interface Props {
  onCodeApplied: (discountCode: string, price: string) => void;
  onCodeRemoved: () => void;
  discountCode?: string;
  onDiscountCodeChanged: (discountCode: string) => void;
  discountCodeApplied: boolean;
  setDiscountCodeApplied: (applied: boolean) => void;
  error?: string;
  companyProduct: CompanyProduct;
  date: string;
  time: string;
  attendeesCount?: number | null;
}

const DiscountCodeInput = ({
  onCodeApplied,
  onCodeRemoved,
  discountCode,
  onDiscountCodeChanged,
  discountCodeApplied,
  setDiscountCodeApplied,
  error,
  companyProduct,
  date,
  time,
  attendeesCount,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invalidCode, setInvalidCode] = useState<boolean>(false);

  const {t} = useTranslation();

  useEffect(() => {
    if (invalidCode) {
      setInvalidCode(false);
    }
  }, [error]);

  const onApplyClick = async () => {
    try {
      setIsLoading(true);
      setInvalidCode(false);

      const {data: discountCodeApplyResultData} = await applyProductDiscountCode(companyProduct.id, {
        discount_code: discountCode!,
        date,
        time,
        attendees_count: attendeesCount ?? undefined,
      });

      onCodeApplied(discountCode!, discountCodeApplyResultData.discounted_price);

      setDiscountCodeApplied(true);

      setIsLoading(false);
    } catch (e) {
      const parsedError = parseError(e);

      if (parsedError.code === 'invalid_discount_code') {
        setInvalidCode(true);
      }

      setIsLoading(false);
    }
  };

  const onRemoveClick = () => {
    onCodeRemoved();
    setDiscountCodeApplied(false);
    onDiscountCodeChanged('');
  };

  const onDiscountCodeInputChange = (e: any) => {
    if (discountCodeApplied) {
      setDiscountCodeApplied(false);
    }

    onDiscountCodeChanged(e.target.value);
  };

  const applyDisabled = useMemo(() => {
    if (!discountCode || isLoading) {
      return true;
    }

    if (discountCode.length < 4) {
      return true;
    }
  }, [discountCode, isLoading]);

  return (
    <div key='discount-code' className='join'>
      <div className='w-full'>
        <input
          name='discount_code'
          type='text'
          className='input input-primary focus:outline-none focus:border-2 focus:border-primary w-full join-item'
          placeholder={t('discount_code')!}
          onChange={onDiscountCodeInputChange}
          value={discountCode}
          disabled={discountCodeApplied}
        />
        {error && <p className='mt-1 text-error text-sm font-medium'>{error}</p>}
        {invalidCode && <p className='mt-1 text-error text-sm font-medium'>{t('discount_code_invalid')}</p>}
      </div>
      {!discountCodeApplied && (
        <button type='button' disabled={applyDisabled} className='btn btn-accent join-item' onClick={onApplyClick}>
          {t('apply')}
        </button>
      )}
      {discountCodeApplied && (
        <button type='button' className='btn btn-accent join-item' onClick={onRemoveClick}>
          <IoCloseSharp size={16} />
        </button>
      )}
    </div>
  );
};

export default DiscountCodeInput;
