const commonDomainMistypes: {[domain: string]: string[]} = {
  'gmail.com': [
    'gmai.com',
    'gmail.co',
    'gamil.com',
    'gmial.com',
    'gmail.con',
    'gimail.com',
    'gmal.com',
    'glail.com',
    'gamail.com',
  ],
  'yahoo.com': ['yaho.com', 'yahoo.co', 'yhoo.com', 'yaoo.com', 'yahoo.con', 'yahoo.vom', 'jahoo.com'],
  'hotmail.com': ['hotmal.com', 'hotmai.com', 'hotmial.com', 'hotail.com', 'hotmail.con'],
  'outlook.com': ['outlok.com', 'otulook.com', 'outook.com', 'outllok.com', 'outlook.con'],
};

const commonTldMistypes: {[tld: string]: string[]} = {
  com: ['con', 'cmo', 'ocm', 'ccom'],
  net: ['nte', 'ent', 'met', 'het'],
  org: ['ogr', 'rog', 'prg', 'otg'],
  edu: ['ude', 'ed', 'eud', 'eduu'],
  gov: ['ogv', 'gvo', 'fov', 'hov'],
  mil: ['iml', 'mli', 'mil', 'mik'],
  info: ['inof', 'inf', 'ifo', 'infp'],
  biz: ['bzi', 'bix', 'buz', 'bzi'],
  co: ['oc', 'cp', 'xo', 'ci'],
  uk: ['ku', 'uj', 'ik', 'ul'],
};

type Result =
  | {
      isValid: false;
      correctPart: string;
      correctedPart: string;
    }
  | {isValid: true};

export const checkCommonEmailMistypes = (email: string): Result => {
  const [localPart, domain] = email.split('@');

  for (const correctDomain in commonDomainMistypes) {
    if (commonDomainMistypes[correctDomain].includes(domain)) {
      return {
        isValid: false,
        correctPart: localPart + '@',
        correctedPart: correctDomain,
      };
    }
  }

  const domainParts = domain.split('.');
  const tld = domainParts[domainParts.length - 1];

  for (const correctTld in commonTldMistypes) {
    if (commonTldMistypes[correctTld].includes(tld)) {
      const domainWithoutTld = domainParts.slice(0, -1).join('.');

      return {
        isValid: false,
        correctPart: localPart + '@' + domainWithoutTld + '.',
        correctedPart: correctTld,
      };
    }
  }

  return {
    isValid: true,
  };
};
