import {useEffect, useMemo, useRef, useState} from 'preact/compat';
import {sendEvent} from '../services/iframeEventSender.ts';
import Booking, {EventData} from '../components/Booking.tsx';
import {CompanyProduct} from '../api/ProductAPI.ts';
import {useTranslation} from 'react-i18next';
import {fetchFlow, fetchFlowProducts, Flow, FlowPaymentData} from '../api/FlowAPI.ts';
import {BookingData} from '../api/BookingAPI.ts';
import {ApiErrorResponse} from '../services/api.ts';
import {parseError} from '../services/errorHandler.ts';
import Loading from '../components/Loading.tsx';
import {IoCloseSharp} from 'react-icons/io5';
import {setFlowStyle} from '../services/flowColors.ts';
import {resolvePoweredByUrl} from '../services/poweredBy.ts';

interface Props {
  flowId: string;
}

const EmbeddedBookingPage = ({flowId}: Props) => {
  const query = new URLSearchParams(window.location.search);
  const bookingId = query.get('bookingId') || null;
  const checkoutDataQuery = query.get('data') || null;
  const productIdsQuery = query.get('productIds') || null;
  const productIds = productIdsQuery ? productIdsQuery.split(',').map((id) => id.trim()) : undefined;

  const [stateAction, setStateAction] = useState<'success' | 'cancel' | 'status' | null | string>(
    query.get('action') || null
  );
  const [showBooking, setShowBooking] = useState<boolean | null>(null);
  const [companyProducts, setCompanyProducts] = useState<CompanyProduct[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [flow, setFlow] = useState<Flow | null>(null);
  const [checkoutData, setCheckoutData] = useState<FlowPaymentData | null>(null);
  const [error, setError] = useState<ApiErrorResponse | null>(null);
  const [isResized, setIsResized] = useState<boolean>(true);
  const [flowStarted, setFlowStarted] = useState<boolean>(false);

  const heightRef = useRef<number | null>(null);

  const {t, i18n} = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const {data: flowData} = await fetchFlow(flowId);

        if (stateAction !== null) {
          flowData.embedding_settings.modal = true;
        }

        if (flowData.style !== null) {
          setFlowStyle(flowData.style);
        }

        setFlow(flowData);

        sendEvent<{settings: any; flowId: string}>(
          'moizmoFlowLoaded',
          {
            settings: flowData.embedding_settings,
            flowId,
          },
          flowData.embedding_settings.domain
        );

        if (stateAction !== null) {
          setShowBooking(true);

          if (stateAction === 'success' && checkoutDataQuery !== null) {
            setCheckoutData({
              data: checkoutDataQuery,
              ss1: new URLSearchParams(window.location.search).get('ss1') || undefined,
              ss2: new URLSearchParams(window.location.search).get('ss2') || undefined,
            });
          }
        } else if (flowData.embedding_settings.modal && !flowData.embedding_settings.button) {
          setShowBooking(true);
        }

        const {data: flowProductsData} = await fetchFlowProducts(flowId, productIds);
        setCompanyProducts(flowProductsData);

        sendEvent<{name: string; data: any}>(
          'moizmoEvent',
          {
            name: 'booking_loaded',
            data: {
              flow: {
                id: flowId,
                type: 'embedding',
                title: flowData.title,
              },
            },
          },
          flowData.embedding_settings.domain
        );

        const height = document.body.scrollHeight;

        heightRef.current = height;

        sendEvent<{height: number; flowId: string}>(
          'moizmoBookingResize',
          {
            height,
            flowId,
          },
          flowData.embedding_settings.domain
        );

        setLoading(false);
      } catch (e) {
        const parsedError = parseError(e);

        setError(parsedError);

        setLoading(false);
      }
    })();
  }, [flowId]);

  useEffect(() => {
    if (error !== null) {
      const height = document.body.scrollHeight;

      heightRef.current = height;

      sendEvent<{height: number; flowId: string; error: boolean}>(
        'moizmoBookingResize',
        {
          error: true,
          height,
          flowId,
        },
        '*'
      );
    }
  }, [error]);

  useEffect(() => {
    if (flow !== null && !flow!.embedding_settings.modal) {
      const heightCheckInterval = setInterval(() => {
        if (heightRef.current !== document.body.scrollHeight) {
          const height = document.body.scrollHeight;
          heightRef.current = height;

          sendEvent<{height: number; flowId: string}>(
            'moizmoBookingResize',
            {
              height,
              flowId,
            },
            flow.embedding_settings.domain
          );
        }
      }, 100);

      return () => clearInterval(heightCheckInterval);
    }
  }, [flow]);

  useEffect(() => {
    if (flow !== null && showBooking !== null) {
      if (showBooking) {
        if (flow.embedding_settings.modal) {
          sendEvent<{open: boolean; flowId: string}>(
            'moizmoModal',
            {
              open: true,
              flowId,
            },
            flow.embedding_settings.domain
          );
        }
      } else {
        if (flow.embedding_settings.modal) {
          setStateAction(null);
          sendEvent<{open: boolean; flowId: string}>(
            'moizmoModal',
            {
              open: false,
              flowId,
            },
            flow.embedding_settings.domain
          );
        }
      }
    }

    setIsResized(true);
  }, [flow, showBooking]);

  const onReservationClick = () => {
    setIsResized(false);

    if (showBooking === null) {
      sendEvent<{flowId: string}>('moizmoReservationButtonClick', {flowId}, flow!.embedding_settings.domain);
      setShowBooking(true);
    } else {
      setShowBooking(!showBooking);
    }
  };

  const onModalClose = () => {
    setIsResized(false);
    setShowBooking(false);
  };

  const className = useMemo(() => {
    if (error !== null) {
      return 'max-w-2xl justify-center mx-auto';
    }

    if (flow !== null) {
      let name = '';

      if (!flow.embedding_settings.modal || !showBooking) {
        name +=
          flow.embedding_settings.position === 'center'
            ? 'justify-center mx-auto'
            : flow.embedding_settings.position === 'right'
            ? 'justify-end ml-auto'
            : 'justify-start mr-auto';
      }

      if (
        flow.is_timetable &&
        companyProducts &&
        (companyProducts.length > 2 || flow.timetable_type === 'horizontal') &&
        stateAction === null
      ) {
        if (flow.embedding_settings.modal) {
          name += ' max-w-4xl';
        } else {
          name += ' max-w-5xl';
        }
      } else {
        if (flow.embedding_settings.modal) {
          name += ' max-w-xl';
        } else {
          name += ' max-w-2xl';
        }
      }

      if (flow.with_watermark && !flowStarted && stateAction === null && flow.embedding_settings.modal) {
        name += ' pb-12';
      }

      return name;
    }

    return 'max-w-xl';
  }, [flow, error, companyProducts, stateAction, showBooking, flowStarted]);

  const onEvent = (name: string, data: EventData) => {
    const eventData = {
      flow: {
        id: flow!.id,
        type: 'embedding',
        title: flow!.title,
      },
      ...data,
    };

    sendEvent<{name: string; data: any}>(
      'moizmoEvent',
      {
        name,
        data: eventData,
      },
      flow!.embedding_settings.domain
    );
  };

  const onCheckoutStart = (productBooking: BookingData) => {
    if (productBooking!.checkout_data!.redirect_url) {
      sendEvent<{
        url: string;
      }>('moizmoRedirect', {url: productBooking!.checkout_data!.redirect_url}, flow!.embedding_settings.domain);
    }
  };

  const onSubStepChanged = () => {
    if (!flowStarted) {
      setFlowStarted(true);
    }
  };

  const renderContent = () => {
    if (flow === null) {
      return null;
    }

    if (flow.embedding_settings.button && !showBooking) {
      return (
        <button className='btn btn-primary moizmo-booking-button' onClick={onReservationClick}>
          {flow.embedding_settings?.button_text || t('reservation')}
        </button>
      );
    }

    if (flow.embedding_settings.modal) {
      if (showBooking) {
        return (
          <div className='modal modal-open'>
            <div
              className={`modal-box text-base-content bg-base-100 ${className} lg:px-8 px-4 lg:py-12 pt-12 overflow-x-hidden flex flex-col w-[96%] lg:mb-0 mb-[4em]`}
            >
              <button
                className='btn btn-sm btn-circle border-base-200 btn-outline absolute right-2 top-2 z-10'
                onClick={onModalClose}
              >
                <IoCloseSharp size={16} />
              </button>
              {loading && <Loading />}
              {error !== null && (
                <div
                  className={`rounded-2xl bg-base-100 w-full flex justify-center items-center text-center text-error pt-4 ${
                    flow.with_watermark ? 'pb-8' : ''
                  }`}
                >
                  {error.message}
                </div>
              )}
              {!loading && error === null && (
                <>
                  {flow.title !== null && flow.always_show_title && (
                    <h1 className='text-2xl font-medium text-center whitespace-pre-wrap mb-8'>{flow.title}</h1>
                  )}
                  <Booking
                    checkoutData={checkoutData}
                    action={stateAction}
                    bookingId={bookingId}
                    onCheckoutStart={onCheckoutStart}
                    flow={flow}
                    companyProducts={companyProducts!}
                    embedded
                    onEvent={onEvent}
                    onSubStepDone={onSubStepChanged}
                  />
                </>
              )}
              {flow.with_watermark && !flowStarted && stateAction === null && (
                <div className='text-gray-400 text-xs absolute bottom-2 right-4'>
                  <a target='_blank' href={resolvePoweredByUrl(i18n.language, flowId)}>
                    Powered by Moizmo Booking
                  </a>
                </div>
              )}
            </div>
          </div>
        );
      } else {
        return null;
      }
    }

    if (loading) {
      return (
        <div className='rounded-2xl text-base-content bg-base-100 w-full'>
          <Loading />
        </div>
      );
    }

    if (error !== null) {
      return (
        <div className='rounded-2xl bg-base-100 w-full flex justify-center items-center text-center text-error p-8'>
          {error.message}
        </div>
      );
    }

    return (
      <div className='w-full text-base-content bg-base-100 rounded-2xl p-4 min-h-[430px] relative'>
        <Booking
          checkoutData={checkoutData}
          action={stateAction}
          bookingId={bookingId}
          onCheckoutStart={onCheckoutStart}
          flow={flow}
          companyProducts={companyProducts!}
          embedded
          onEvent={onEvent}
        />
      </div>
    );
  };

  return <div className={`flex ${className} ${isResized ? 'opacity-100' : 'opacity-0'}`}>{renderContent()}</div>;
};

export default EmbeddedBookingPage;
