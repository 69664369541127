export const sendEvent = <T>(type: string, data: T, origin: string) => {
  if (window === window.parent) {
    return;
  }

  window.parent.postMessage(
    {
      type,
      ...data,
    },
    origin
  );
}
