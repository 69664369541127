import Router, {Route} from 'preact-router';
import EmbeddedBookingPage from './routes/EmbeddedBookingPage.tsx';
import BookingPage from './routes/BookingPage.tsx';
import {Suspense} from 'preact/compat';
import i18n from './i18n.ts';

export function App() {
  function handleRoute(route: any) {
    const lang = route.matches.lang || 'en';

    i18n.init({
      backend: {
        loadPath: '/locales/{{lng}}/translation.json?' + import.meta.env.VITE_TRANSLATIONS_HASH,
      },
      lng: lang,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
      },
    });
  }

  return (
    <Suspense fallback=''>
      <main>
        <Router onChange={handleRoute}>
          <Route path='/:lang/booking/:flowId/embedded' component={EmbeddedBookingPage} />
          <Route path='/booking/:flowId/embedded' component={EmbeddedBookingPage} />
          <Route path='/:lang/booking/:flowId' component={BookingPage} />
          <Route path='/booking/:flowId' component={BookingPage} />
        </Router>
      </main>
    </Suspense>
  );
}
