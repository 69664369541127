import {RiEditFill} from 'react-icons/ri';
import {ProductAvailableTime} from '../../api/ProductAPI.ts';

interface Props {
  selectedTime: ProductAvailableTime;
  onTimeChangeClick: () => void;
  hideEndTime: boolean;
}

const SelectedTime = ({selectedTime, onTimeChangeClick, hideEndTime}: Props) => {
  return (
    <div
      key='selected-time'
      className='selected-booking-item flex justify-center flex-wrap gap-1'
      onClick={onTimeChangeClick}
    >
      {selectedTime.from}{!hideEndTime && ` - ${selectedTime.to}`}
      {selectedTime.price !== null && <div>({selectedTime.price}€)</div>}
      <RiEditFill className='absolute top-1/2 -translate-y-1/2 right-4' />
    </div>
  );
};

export default SelectedTime;
