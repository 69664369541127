import {useEffect, useRef, useState} from 'preact/compat';
import ReactPhoneInput, {CountryData} from 'react-phone-input-2';
import {ContactFormField} from '../../../api/ProductAPI.ts';
import {useTranslation} from 'react-i18next';

import './PhoneField.css';

const localeFiles: any = {
  ru: () => import('react-phone-input-2/lang/ru.json'),
  lt: () => import('./localization/lt.json'),
  uk: () => import('./localization/uk.json'),
};

const PhoneInput: typeof ReactPhoneInput = ReactPhoneInput.default ? ReactPhoneInput.default : ReactPhoneInput;

interface Props {
  name: string;
  label: string;
  onChange: (e: any) => void;
  required: boolean;
  defaultValue?: string;
  error?: string;
  defaultPhoneCountryData?: ContactFormField['default_phone_country'];
}

const PhoneField = ({name, label, onChange, required, defaultValue, error, defaultPhoneCountryData}: Props) => {
  const [phone, setPhone] = useState<string>(defaultValue || `+${defaultPhoneCountryData?.country_code || '370'}`);
  const [localization, setLocalization] = useState<{[key: string]: string} | null>(null);

  const [phoneCountryData, setPhoneCountryData] = useState<{dialCode: string; countryCode: string}>({
    dialCode: defaultPhoneCountryData?.dial_code || '370',
    countryCode: defaultPhoneCountryData?.country_code || 'lt',
  });

  const phoneInputRef = useRef<HTMLInputElement>(null);
  const phoneCountryDataRef = useRef(phoneCountryData);

  const {
    t,
    i18n: {language},
  } = useTranslation();

  useEffect(() => {
    phoneCountryDataRef.current = phoneCountryData;
  }, [phoneCountryData]);

  useEffect(() => {
    if (required && defaultPhoneCountryData) {
      (async () => {
        try {
          if (localeFiles[language]) {
            const localizationFile = await localeFiles[language]();
            setLocalization(localizationFile.default || localizationFile);
          }
        } catch (e) {

        }
      })();
    }
  }, [required, defaultPhoneCountryData]);

  const handlePhoneInputChange = (e: any) => {
    if (phoneInputRef.current === null) {
      return;
    }

    const currentPhoneCountryData = phoneCountryDataRef.current;
    const sanitizedValue = e.target.value.replace(/[^\d+]/g, '');

    phoneInputRef.current.value = e.target.value;

    if (!phoneInputRef.current.value.startsWith(`+${currentPhoneCountryData.dialCode}`)) {
      phoneInputRef.current.value = `+${currentPhoneCountryData.dialCode}`;
    } else {
      phoneInputRef.current.value = sanitizedValue;
    }

    setPhone(phoneInputRef.current.value);

    onChange(e);
  };

  const handlePhoneCountryChange = (_value: string, data: CountryData, e: any) => {
    if (phoneInputRef.current === null) {
      return;
    }

    if (phoneCountryData.countryCode !== data.countryCode) {
      setPhoneCountryData({
        countryCode: data.countryCode,
        dialCode: data.dialCode,
      });

      phoneInputRef.current.value = `+${data.dialCode}`;

      onChange(e);
    }
  };

  if (!required || !defaultPhoneCountryData) {
    return (
      <div key={name}>
        <input
          name={name}
          type='tel'
          required={required}
          className='input input-primary focus:outline-none focus:border-2 focus:border-primary w-full'
          placeholder={label}
          onChange={onChange}
          defaultValue={defaultValue}
        />
        {error && <p className='mt-1 text-error text-sm font-medium'>{error}</p>}
      </div>
    );
  }

  return (
    <div>
      <PhoneInput
        disableSearchIcon
        enableSearch
        key={localization !== null ? 'localized' : 'default'}
        country={phoneCountryData.countryCode}
        inputClass='phone-input'
        placeholder={label}
        searchPlaceholder={t('search')}
        localization={localization ?? {}}
        inputProps={{
          required: required,
          type: 'tel',
          name: name,
          ref: phoneInputRef,
          onChange: handlePhoneInputChange,
        }}
        value={phone}
        specialLabel=''
        onChange={handlePhoneCountryChange}
      />
      {error && <p className='mt-1 text-error text-sm font-medium'>{error}</p>}
    </div>
  );
};

export default PhoneField;
