import axios, {AxiosResponse} from 'axios';
import i18n from '../i18n';

export interface ApiErrorResponse {
  code: string;
  message: string;
  additional_data?: {
    field: any;
    message: string;
    code: string;
  }[];
}

export type ApiPromise<T> = Promise<AxiosResponse<T>>;

export class ApiError extends Error {
  code;
  additional_data;

  constructor(error: ApiErrorResponse) {
    super(error.message);

    this.code = error.code;
    this.additional_data = error.additional_data;
  }
}

export const buildAuthorizationHeader = (token: string) => `Bearer ${token}`;

export const prepareData = (data: {[key: string]: any}) =>
  Object.keys(data).reduce((prev, current) => {
    if (data[current] === undefined) {
      return prev;
    }

    return {
      ...prev,
      [current]: data[current] !== '' ? data[current] : null,
    };
  }, {});

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 7000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config: any) => {
    config.headers = {
      ...config.headers,
      'X-Language': i18n.language,
    };

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.code) {
      return Promise.reject(new ApiError(error.response.data));
    }

    return Promise.reject(error);
  }
);

export default api;
