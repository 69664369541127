import {FaMinus, FaPlus} from 'react-icons/fa';
import {IoCheckmarkSharp} from 'react-icons/io5';
import {useTranslation} from 'react-i18next';

interface Props {
  onAttendeesConfirm: (count: number) => void;
  attendeesCount: number;
  setAttendeesCount: (count: number) => void;
  minimum: number;
  maximum: number;
  title: string;
}

const AttendeesSelection = ({
  onAttendeesConfirm,
  attendeesCount,
  setAttendeesCount,
  minimum,
  maximum,
  title,
}: Props) => {
  const {t} = useTranslation();

  const increaseAttendees = () => {
    if (!attendeesCount) {
      setAttendeesCount(minimum);

      return;
    }

    if (attendeesCount < maximum) {
      setAttendeesCount(attendeesCount + 1);
    }
  };

  const decreaseAttendees = () => {
    if (!attendeesCount) {
      setAttendeesCount(minimum);

      return;
    }

    if (attendeesCount > minimum) {
      setAttendeesCount(attendeesCount - 1);
    }
  };

  const onAttendeesConfirmClick = () => {
    if (!attendeesCount) {
      setAttendeesCount(minimum);
      return;
    }

    if (attendeesCount > maximum) {
      setAttendeesCount(maximum);
      return;
    }

    if (attendeesCount < minimum) {
      setAttendeesCount(minimum);
      return;
    }

    onAttendeesConfirm(attendeesCount);
  };

  return (
    <>
      <h2 className='text-xl font-semibold text-center'>{title}</h2>
      <div className='mb-6 text-center'>
        <span className='mr-4'>
          {t('min')}: {minimum}
        </span>
        <span>
          {t('max')}: {maximum}
        </span>
      </div>
      <div className='flex justify-center items-center mb-6'>
        <button type='button' className='border rounded-full p-1 cursor-pointer mr-2' onClick={decreaseAttendees}>
          <FaMinus size={28} className={attendeesCount <= minimum ? 'text-base-300' : ''} />
        </button>
        <input
          autoComplete='off'
          type='number'
          value={attendeesCount}
          onChange={({target}: any) => setAttendeesCount(parseInt(target.value))}
          className='input input-sm w-28 text-center font-semibold text-3xl hover:outline-0 focus:outline-0'
        />
        <button type='button' className='border rounded-full p-1 cursor-pointer ml-2' onClick={increaseAttendees}>
          <FaPlus size={28} className={attendeesCount >= maximum ? 'text-base-300' : ''} />
        </button>
      </div>
      <div className='text-center'>
        <button className='btn btn-primary btn-circle' onClick={onAttendeesConfirmClick}>
          <IoCheckmarkSharp size={32} />
        </button>
      </div>
    </>
  );
};

export default AttendeesSelection;
