import {formatDate} from '../../services/dateFormat.ts';
import {RiEditFill} from 'react-icons/ri';

interface Props {
  selectedDate: Date;
  onDateChangeClick: () => void;
}

const SelectedDate = ({selectedDate, onDateChangeClick}: Props) => {
  return (
    <div
      key='selected-date'
      className='selected-booking-item'
      onClick={onDateChangeClick}
    >
      {formatDate(selectedDate!)}
      <RiEditFill className='absolute top-1/2 -translate-y-1/2 right-4' />
    </div>
  );
};

export default SelectedDate;
