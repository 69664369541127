import {RouterProps} from 'preact-router';
import Booking, {Step, SubStep} from '../components/Booking.tsx';
import {useEffect, useRef, useState} from 'preact/compat';
import {CompanyProduct} from '../api/ProductAPI.ts';
import {useTranslation} from 'react-i18next';
import {fetchFlow, fetchFlowProducts, Flow, FlowPaymentData} from '../api/FlowAPI.ts';
import {BookingData} from '../api/BookingAPI.ts';
import {parseError} from '../services/errorHandler.ts';
import {ApiErrorResponse} from '../services/api.ts';
import Loading from '../components/Loading.tsx';
import {setFlowStyle} from '../services/flowColors.ts';
import {resolvePoweredByUrl} from '../services/poweredBy.ts';

interface Props extends RouterProps {
  flowId: string;
}

const BookingPage = ({flowId}: Props) => {
  const query = new URLSearchParams(window.location.search);

  const stateAction = query.get('action') || null;
  const checkoutDataQuery = query.get('data') || null;
  const bookingId = query.get('bookingId') || null;
  const productIdsQuery = query.get('productIds') || null;
  const productIds = productIdsQuery ? productIdsQuery.split(',').map((id) => id.trim()) : undefined;

  const [companyProducts, setCompanyProducts] = useState<CompanyProduct[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [flow, setFlow] = useState<Flow | null>(null);
  const [checkoutData, setCheckoutData] = useState<FlowPaymentData | null>(null);
  const [error, setError] = useState<ApiErrorResponse | null>(null);
  const [currentStep, setCurrentStep] = useState<Step | null>(null);
  const [flowStarted, setFlowStarted] = useState<boolean>(false);

  const {t, i18n} = useTranslation();

  const bookingRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const {data: flowData} = await fetchFlow(flowId);

        setFlow(flowData);

        if (flowData.style !== null) {
          setFlowStyle(flowData.style);
          document.body.className = 'bg-base-100 text-base-content';
        }

        const {data: flowProductsData} = await fetchFlowProducts(flowId, productIds);

        setCompanyProducts(flowProductsData);

        if (stateAction === 'success' && checkoutDataQuery !== null) {
          setCheckoutData({
            data: checkoutDataQuery,
            ss1: new URLSearchParams(window.location.search).get('ss1') || undefined,
            ss2: new URLSearchParams(window.location.search).get('ss2') || undefined,
          });
        }

        setLoading(false);
      } catch (e) {
        const parsedError = parseError(e);

        setError(parsedError);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onStepChange = (step: Step) => {
    if (currentStep !== null) {
      if (!flowStarted) {
        setFlowStarted(true);
      }

      if (bookingRef && bookingRef.current) {
        window.scrollTo({top: bookingRef.current.offsetTop - 60});
      }
    }

    setCurrentStep(step);
  };

  const onSubStepDone = (subStep: SubStep) => {
    if ((subStep === 'category' || subStep === 'date') && !flowStarted) {
      setFlowStarted(true);
    }
  };

  const onCheckoutStart = (productBooking: BookingData) => {
    if (productBooking.checkout_data?.redirect_url) {
      window.location.href = productBooking.checkout_data.redirect_url;
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className='md:mt-0 mt-[50%]'>
          <Loading />
        </div>
      );
    }

    if (error !== null) {
      return <div className='text-center text-error'>{error.message}</div>;
    }

    return (
      <div className='h-full overflow-y-auto flex flex-col'>
        {stateAction === null && (
          <>
            {(!flowStarted || flow!.always_show_title) && (
              <>
                {flow!.logo_url !== null && !flowStarted && (
                  <div className='flex justify-center mb-4'>
                    <img
                      className='rounded-2xl w-[120px] h-[120px]'
                      src={flow!.logo_url}
                      alt={`${flow!.company_name} logo`}
                    />
                  </div>
                )}
                <h1 className='text-2xl font-medium mb-1 text-center whitespace-pre-wrap'>
                  {flow!.title || flow!.company_name}
                </h1>
              </>
            )}
            <h2 className='text-xl mb-8 text-center'>{t('reservation')}</h2>
          </>
        )}
        <div className='flex flex-col overflow-y-auto px-1 pb-1' ref={bookingRef}>
          <Booking
            onStepChange={onStepChange}
            onSubStepDone={onSubStepDone}
            checkoutData={checkoutData}
            action={stateAction}
            bookingId={bookingId}
            onCheckoutStart={onCheckoutStart}
            flow={flow!}
            companyProducts={companyProducts!}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='lg:tall-lg:h-screen bg-base-100 text-base-content'>
      <div className='container px-0 flex lg:justify-center min-h-full md:items-center flex-col lg:py-8 h-full overflow-y-hidden'>
        <div
          className={`lg:grow-0 grow ${
            flow !== null && flow.is_timetable ? 'max-w-5xl' : 'max-w-xl'
          } mx-auto w-full h-full flex flex-col lg:justify-center overflow-y-hidden`}
        >
          <div
            className={`rounded-2xl lg:px-7 lg:py-12 py-6 px-4 relative md:border-2 ${
              flow !== null ? 'md:border-secondary' : 'md:border-base-300'
            } md:border-dashed md:mt-8 lg:mt-0 max-h-full overflow-y-auto overflow-x-hidden`}
          >
            {renderContent()}
          </div>
        </div>
        {flow !== null && !flowStarted && flow.with_checkout && (
          <div className='mt-12 text-center text-sm'>
            <div className='font-bold mb-1'>{t('legal_data')}</div>
            <div>
              <span className='font-medium'>
                {flow.company_legal_data.type === 'company' ? t('legal_company_name') : t('legal_self_employment_name')}
                :
              </span>{' '}
              {flow.company_legal_data.legal_name}
            </div>
            {flow.company_legal_data.code !== null && (
              <div>
                <span className='font-medium'>
                  {flow.company_legal_data.type === 'company'
                    ? t('legal_company_code')
                    : t('legal_self_employment_code')}
                  :
                </span>{' '}
                {flow.company_legal_data.code}
              </div>
            )}
            <div>
              <span className='font-medium'>{t('email')}:</span> {flow.company_legal_data.email}
            </div>
            {flow.company_legal_data.phone !== null && (
              <div>
                <span className='font-medium'>{t('phone')}:</span> {flow.company_legal_data.phone}
              </div>
            )}
          </div>
        )}
        {flow !== null && !flowStarted && flow.with_watermark && (
          <div className='text-gray-400 text-xs mt-2 text-center'>
            <a target='_blank' href={resolvePoweredByUrl(i18n.language, flowId)}>
              Powered by Moizmo Booking
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingPage;
